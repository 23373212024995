import React, { useEffect, useState } from 'react';
import {
  ROUTE,
  SESSION_KEY,
  TEXT,
  generateUrl,
  getFromAsync,
  getUrlParams,
} from '../../services/utils';
import { toast } from 'react-toastify';
import { MODE } from '../../services/enum';
import { useTranslation } from 'react-i18next';
import DynamicForm from '../../components/form';
import { post } from '../../services/ApiServices';
import API_CONSTANT from '../../services/ApiConstant';
import { HeaderSimple } from '../../components/header';
import { ComponentLoading } from '../../components/loading';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import { decrypt, encrypt } from '../../services/encryptDecryptService';
import { getFormValues, getSearchApiData } from '../../services/dynamicFormHelper';

const NewForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const path = useResolvedPath();
  const userData = getFromAsync(SESSION_KEY.USER_DATA);
  const paramData = getUrlParams({ data: path.pathname.replace(`${ROUTE?.FORM}/`, '') });
  const appData = decrypt(paramData);

  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const apiObj = {
      Mode: MODE.WEB_MODE,
      AppUserId: userData?.AppUserId,
      InputText: encrypt({ ApplicationTypeId: appData?.data?.ApplicationTypeId }),
    };

    post(API_CONSTANT.APPLICATION_DATA, apiObj)
      .then((res) => setFormData(decrypt(res.data.OutPutText)?.Result || []))
      .catch((e) => toast.error(e?.data?.ErrorMessage))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmission = (val) => {
    setIsLoading(true);
    return new Promise((resolve, reject) => {
      post(API_CONSTANT.APPLICATION_DATA_ADD, {
        Mode: MODE.WEB_MODE,
        AppUserId: userData?.AppUserId,
        InputText: encrypt({
          ApplicationTypeId: appData?.data?.ApplicationTypeId,
          AppDataList: getFormValues(val),
        }),
      })
        .then(({ data }) => {
          const res = decrypt(data?.OutPutText);
          if (res?.IsSucess) {
            setIsLoading(false);
            toast.success(res?.Message);
            navigate(
              generateUrl(
                ROUTE.APP_DETAILS,
                encrypt({
                  data: res?.ApplicationId,
                }),
              ),
              '_blank',
            );
            resolve();
          } else {
            setIsLoading(false);
            toast.error(res?.Message);
          }
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e?.data?.ErrorMessage);
        })
        .finally(reject);
    });
  };

  return (
    <div className="h-full w-full bg-ghost-white">
      <HeaderSimple title={`${appData?.data?.ApplicationTypeName} ${t(TEXT?.FORM)}`} home />

      <div className="flex flex-1 items-center justify-center bg-white text-left">
        {isLoading ? (
          <ComponentLoading loading={isLoading} className="pt-3" />
        ) : (
          <div className="w-full rounded-t-3xl bg-white pt-5">
            <DynamicForm fields={formData} apiCall={getSearchApiData} onSubmit={handleSubmission} />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewForm;
