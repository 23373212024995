import * as React from 'react';

const Close = (props) => (
  <svg
    width={props.w}
    height={props.h}
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.9971 7.63432L7.99707 25.6343"
      stroke="#5C5C5C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.9971 25.6343L7.99707 7.63432"
      stroke="#5C5C5C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Close;
