import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DocSideBar from '../../components/docSideBar';
import API_CONSTANT from '../../services/ApiConstant';
import { HeaderSimple } from '../../components/header';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

const GoogleDocViewer = () => {
  const { app } = useSelector((state) => state);

  const [open, setOpen] = useState(true);

  const docs = [
    {
      uri: API_CONSTANT?.BASE_URL + 'api/' + app?.docURL?.FileURL,
    },
  ];

  return (
    <div className="h-full w-full bg-ghost-white">
      <HeaderSimple title={'Google Doc Viewer Sample'} home />

      <div className="bg-white">
        <div className="flex w-full items-center">
          <div className={`${open ? 'w-72' : 'w-[0px]'}`}>
            <DocSideBar open={open} setOpen={setOpen} />
          </div>

          <div className={`${open ? 'w-[calc(100%-288px)]' : 'w-full'}`}>
            <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleDocViewer;
