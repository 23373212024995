import { Radio, FormLabel, RadioGroup, FormHelperText, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import { getRules } from '../../services/dynamicFormHelper';

export const RadioButtonField = ({ field, formControl }) =>
  !field?.options?.length ? null : (
    <Controller
      name={field.id}
      rules={getRules(field)}
      defaultValue={field?.value}
      control={formControl.control}
      render={({ field: { onBlur, onChange, value, name }, fieldState: { error } }) => (
        <RadioGroup id={field.id} onBlur={onBlur} onChange={onChange}>
          <FormLabel error={!!error} component="legend">
            {field.label}
          </FormLabel>
          {field?.options?.map((e, i) => (
            <FormControlLabel
              label={e}
              key={name + i}
              control={<Radio checked={e === value} value={e} />}
            />
          ))}
          <FormHelperText error={!!error}>{error?.message}</FormHelperText>
        </RadioGroup>
      )}
    />
  );
