import React from 'react';
import { useResolvedPath, useRouteError } from 'react-router-dom';
import { TEXT } from '../../services/utils';
import { useTranslation } from 'react-i18next';

const ErrorBoundary = () => {
  const { t } = useTranslation();

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      <p>{t(TEXT?.THIS_IS_ERROR_PAGE)}</p>
      <p className="text-blue-500">{useResolvedPath().pathname}</p>
      <code className="text-red-500">{String(useRouteError())}</code>
    </div>
  );
};

export default ErrorBoundary;
