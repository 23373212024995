import { Controller } from 'react-hook-form';
import { getRules } from '../../services/dynamicFormHelper';
import { Checkbox, FormHelperText, FormLabel } from '@mui/material';

export const CheckMarkField = ({ field, formControl }) => (
  <Controller
    name={field.id}
    rules={getRules(field)}
    control={formControl.control}
    defaultValue={field?.value || false}
    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
      <span className="w-max max-w-0" onBlur={onBlur}>
        <Checkbox checked={value} component={'span'} onChange={(e) => onChange(e.target.checked)} />
        <FormLabel error={error?.message} component={'span'} sx={{ color: 'black', mr: 1 }}>
          {field?.label}
        </FormLabel>
        {error?.message && (
          <FormHelperText error={error?.message} component={'span'} sx={{ ml: 0, mr: 1 }}>
            ({error?.message})
          </FormHelperText>
        )}
      </span>
    )}
  />
);
