import React, { useEffect, useState, Fragment } from 'react';
import { toast } from 'react-toastify';
import { MODE } from '../../services/enum';
import icons from '../../config/assets/icons';
import { useTranslation } from 'react-i18next';
import { post } from '../../services/ApiServices';
import API_CONSTANT from '../../services/ApiConstant';
import { useDispatch, useSelector } from 'react-redux';
import { ComponentLoading } from '../../components/loading';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { decrypt } from '../../services/encryptDecryptService';
import { setTagData, setTagList } from '../../store/tags/reducer';
import { HeaderSimple, SearchBar } from '../../components/header';
import { Accordion, AccordionHeader, AccordionBody } from '@material-tailwind/react';
import { DownArrowIcon, ROUTE, SESSION_KEY, TEXT, getFromAsync } from '../../services/utils';

const TagList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { tag } = useSelector((state) => state);
  const userData = getFromAsync(SESSION_KEY?.USER_DATA);

  const [open, setOpen] = useState(0);
  const [open1, setOpen1] = useState(0);
  const [open2, setOpen2] = useState(0);
  const [search, setSearch] = useState('');
  const [openTags, setOpenTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [repliedTags, setRepliedTags] = useState([]);
  const [replyAwaitedTags, setReplyAwaitedTags] = useState([]);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const handleOpen1 = (value) => {
    setOpen1(open1 === value ? 0 : value);
  };

  const handleOpen2 = (value) => {
    setOpen2(open2 === value ? 0 : value);
  };

  useEffect(() => {
    getTagList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTagList = () => {
    setIsLoading(true);

    const apiObj = {
      AppUserId: userData?.AppUserId,
      Mode: MODE.WEB_MODE,
    };

    post(API_CONSTANT.TAG_LIST, apiObj)
      .then((res) => {
        const data = decrypt(res.data.OutPutText);
        dispatch(setTagList(data));
        setOpenTags(data?.MyOpenTags);
        setRepliedTags(data?.RepliedTags);
        setReplyAwaitedTags(data?.ReplyAwaitedTags);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(e?.data?.ErrorMessage || e?.message || String(e));
      });
  };

  const renderOpenTagData = (data) => {
    return (
      <div
        className="flex cursor-pointer"
        onClick={() => {
          dispatch(setTagData(data?.data));
          window.open(ROUTE?.TAG_DETAILS, '_blank');
        }}
      >
        <p className="text-xs sm:text-sm">{data?.displayValue}</p>
      </div>
    );
  };

  const renderRepliedTagData = (data) => {
    return (
      <div
        className="flex cursor-pointer"
        onClick={() => {
          dispatch(setTagData(data?.data));
          window.open(ROUTE?.TAG_DETAILS, '_blank');
        }}
      >
        <p className="text-xs sm:text-sm">{data?.displayValue}</p>
      </div>
    );
  };

  const renderReplyAwaitedData = (data) => {
    return (
      <div
        className="flex cursor-pointer"
        onClick={() => {
          dispatch(setTagData(data?.data));
          window.open(ROUTE?.TAG_DETAILS, '_blank');
        }}
      >
        <p className="text-xs sm:text-sm">{data?.displayValue}</p>
      </div>
    );
  };

  const handleSearch = (search) => {
    const myOpenTagsFilter = tag?.tagList?.MyOpenTags.filter((x, i) => {
      return (
        x.TagNumber.toUpperCase().includes(search.toUpperCase()) ||
        x.TagSummary.toUpperCase().includes(search.toUpperCase()) ||
        x.User.toUpperCase().includes(search.toUpperCase()) ||
        x.TagStatus.toUpperCase().includes(search.toUpperCase())
      );
    });

    setOpenTags(myOpenTagsFilter);
    const repliedTagsFilter = tag?.tagList?.RepliedTags.filter((x, i) => {
      return (
        x.TagNumber.toUpperCase().includes(search.toUpperCase()) ||
        x.TagSummary.toUpperCase().includes(search.toUpperCase()) ||
        x.User.toUpperCase().includes(search.toUpperCase()) ||
        x.TagStatus.toUpperCase().includes(search.toUpperCase())
      );
    });

    setRepliedTags(repliedTagsFilter);
    const replyAwaitedTagsFilter = tag?.tagList?.ReplyAwaitedTags.filter((x, i) => {
      return (
        x.TagNumber.toUpperCase().includes(search.toUpperCase()) ||
        x.TagSummary.toUpperCase().includes(search.toUpperCase()) ||
        x.User.toUpperCase().includes(search.toUpperCase()) ||
        x.TagStatus.toUpperCase().includes(search.toUpperCase())
      );
    });
    setReplyAwaitedTags(replyAwaitedTagsFilter);
  };

  const _search = (val) => {
    if (val === '') {
      setOpenTags(tag?.tagList?.MyOpenTags);
      setRepliedTags(tag?.tagList?.RepliedTags);
      setReplyAwaitedTags(tag?.tagList?.ReplyAwaitedTags);
    }
    handleSearch(val);
    setSearch(val);
  };

  return (
    <div className="h-full w-full bg-ghost-white">
      <HeaderSimple title={'Tag List'} home />

      {isLoading ? (
        <ComponentLoading loading={isLoading} className="bg-white pt-3" />
      ) : (
        <div className="plr3 rounded-t-3xl bg-white pt-5 ">
          <div className="mb-4 sm:w-full md:w-3/4 lg:w-3/6">
            <SearchBar
              placeholder={t(TEXT?.SEARCH)}
              searchValue={search}
              onChange={(e) => _search(e?.target?.value)}
              onKeyDownCapture={(e) => e.key === 'Enter' && handleSearch({ search })}
              LeftIcon={() => (
                <div>
                  <icons.search w={20} h={20} class={''} />
                </div>
              )}
            />
          </div>

          <Fragment>
            <Accordion
              open={open === 0}
              icon={<p className="-ml-5 text-sm sm:text-base">({openTags?.length})</p>}
            >
              <AccordionHeader onClick={() => handleOpen(1)} className="bg-gray-300 px-3 py-1.5">
                <div className="flex items-center">
                  <p className="mr-2 text-start text-sm sm:text-base">{t(TEXT?.MY_OPEN_TAGS)}</p>
                  <DownArrowIcon />
                </div>
              </AccordionHeader>
              <AccordionBody>
                <div>
                  <DataGrid
                    id="grid"
                    showBorders
                    className="w-full"
                    showRowLines={true}
                    dataSource={openTags}
                    wordWrapEnabled={true}
                    columnAutoWidth={true}
                    allowColumnResizing={true}
                    rowAlternationEnabled={true}
                  >
                    <Column
                      sortOrder="asc"
                      allowEditing={false}
                      dataField="TagNumber"
                      caption={`Tag Number`}
                      cellRender={(event) => renderOpenTagData(event)}
                    />
                    <Column
                      alignment={'left'}
                      allowEditing={false}
                      dataField="TagSummary"
                      caption={'Tag Summary'}
                      cellRender={(event) => renderOpenTagData(event)}
                    />
                    <Column
                      alignment={'left'}
                      allowEditing={false}
                      dataField="User"
                      caption={'User'}
                      cellRender={(event) => renderOpenTagData(event)}
                    />
                    <Column
                      alignment={'left'}
                      allowEditing={false}
                      dataField="TagCreatedAgo"
                      caption={'Tag Created'}
                      cellRender={(event) => renderOpenTagData(event)}
                    />
                    <Column
                      alignment={'left'}
                      allowEditing={false}
                      dataField="TagStatus"
                      caption={'Tag Status'}
                      cellRender={(event) => renderOpenTagData(event)}
                    />
                  </DataGrid>
                </div>
              </AccordionBody>
            </Accordion>
            <Accordion
              open={open1 === 0}
              icon={<p className="-ml-5 text-sm sm:text-base">({repliedTags?.length})</p>}
            >
              <AccordionHeader onClick={() => handleOpen1(1)} className="bg-gray-300 px-3 py-1.5">
                <div className="flex items-center">
                  <p className="mr-2 text-start text-sm sm:text-base">{t(TEXT?.REPLIED_TAGS)}</p>
                  <DownArrowIcon />
                </div>
              </AccordionHeader>
              <AccordionBody>
                <div>
                  <DataGrid
                    id="grid"
                    showBorders
                    className="w-full"
                    showRowLines={true}
                    wordWrapEnabled={true}
                    columnAutoWidth={true}
                    dataSource={repliedTags}
                    allowColumnResizing={true}
                    rowAlternationEnabled={true}
                  >
                    <Column
                      sortOrder="asc"
                      allowEditing={false}
                      dataField="TagNumber"
                      caption={`Tag Number`}
                      cellRender={(event) => renderRepliedTagData(event)}
                    />
                    <Column
                      alignment={'left'}
                      allowEditing={false}
                      dataField="TagSummary"
                      caption={'Tag Summary'}
                      cellRender={(event) => renderRepliedTagData(event)}
                    />
                    <Column
                      alignment={'left'}
                      allowEditing={false}
                      dataField="User"
                      caption={'User'}
                      cellRender={(event) => renderRepliedTagData(event)}
                    />
                    <Column
                      alignment={'left'}
                      allowEditing={false}
                      dataField="TagCreatedAgo"
                      caption={'Tag Created'}
                      cellRender={(event) => renderRepliedTagData(event)}
                    />
                    <Column
                      alignment={'left'}
                      allowEditing={false}
                      dataField="TagStatus"
                      caption={'Tag Status'}
                      cellRender={(event) => renderRepliedTagData(event)}
                    />
                  </DataGrid>
                </div>
              </AccordionBody>
            </Accordion>
            <Accordion
              open={open2 === 0}
              icon={<p className="-ml-5 text-sm sm:text-base">({replyAwaitedTags?.length})</p>}
            >
              <AccordionHeader onClick={() => handleOpen2(2)} className="bg-gray-300 px-3 py-1.5">
                <div className="flex items-center">
                  <p className="mr-2 text-start text-sm sm:text-base">
                    {t(TEXT?.REPLY_AWAITED_TAGS)}
                  </p>
                  <DownArrowIcon />
                </div>
              </AccordionHeader>
              <AccordionBody>
                <div>
                  <DataGrid
                    id="grid"
                    showBorders
                    className="w-full"
                    showRowLines={true}
                    wordWrapEnabled={true}
                    columnAutoWidth={true}
                    allowColumnResizing={true}
                    rowAlternationEnabled={true}
                    dataSource={replyAwaitedTags}
                  >
                    <Column
                      sortOrder="asc"
                      allowEditing={false}
                      dataField="TagNumber"
                      caption={`Tag Number`}
                      cellRender={(event) => renderReplyAwaitedData(event)}
                    />
                    <Column
                      alignment={'left'}
                      allowEditing={false}
                      dataField="TagSummary"
                      caption={'Tag Summary'}
                      cellRender={(event) => renderReplyAwaitedData(event)}
                    />
                    <Column
                      alignment={'left'}
                      allowEditing={false}
                      dataField="User"
                      caption={'User'}
                      cellRender={(event) => renderReplyAwaitedData(event)}
                    />
                    <Column
                      alignment={'left'}
                      allowEditing={false}
                      dataField="TagCreatedAgo"
                      caption={'Tag Created'}
                      cellRender={(event) => renderReplyAwaitedData(event)}
                    />
                    <Column
                      alignment={'left'}
                      allowEditing={false}
                      dataField="TagStatus"
                      caption={'Tag Status'}
                      cellRender={(event) => renderReplyAwaitedData(event)}
                    />
                  </DataGrid>
                </div>
              </AccordionBody>
            </Accordion>
          </Fragment>
        </div>
      )}
    </div>
  );
};

export default TagList;
