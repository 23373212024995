import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../constant/lang/en.json";
import hi from "../constant/lang/hi.json";
import de from "../constant/lang/de.json";
import JA from "../constant/lang/JA.json";
import pt from "../constant/lang/pt.json";
import TH from "../constant/lang/TH.json";

const resources = {
  en: {
    translation: en,
  },
  hi: {
    translation: hi,
  },
  de: {
    translation: de,
  },
  JA: {
    translation: JA,
  },
  pt: {
    translation: pt,
  },
  TH: {
    translation: TH,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
