// regular
export const FS12 = { fontSize: 12 };
export const FS14 = { fontSize: 14 };
export const FS16 = { fontSize: 16 };
export const FS17 = { fontSize: 17 };
export const FS18 = { fontSize: 18 };
export const FS20 = { fontSize: 20 };
export const FS23 = { fontSize: 23 };
export const FS32 = { fontSize: 32 };

export const FS16M = { fontSize: 16, fontWeight: "600" };
export const FS20M = { fontSize: 20, fontWeight: "600" };
export const FS32M = { fontSize: 32, fontWeight: "600" };

export const FS14B = { fontSize: 14, fontWeight: "bold" };
export const FS16B = { fontSize: 16, fontWeight: "bold" };
export const FS18B = { fontSize: 18, fontWeight: "bold" };
export const FS20B = { fontSize: 20, fontWeight: "bold" };
export const FS25B = { fontSize: 25, fontWeight: "bold" };
export const FS28B = { fontSize: 28, fontWeight: "bold" };
export const FS32B = { fontSize: 32, fontWeight: "bold" };
export const FS35B = { fontSize: 35, fontWeight: "bold" };
