import { SESSION_KEY } from './utils';
var utf8 = require('utf8');
var base64 = require('base-64');
const CryptoJS = require('crypto-js');

export const decryptURL = (obj) => JSON.parse(utf8.decode(base64.decode(obj)));
export const encryptURL = (obj) => base64.encode(utf8.encode(JSON.stringify(obj)));

const lockObj = { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 };

const getCurrentKey = () => {
  const key = CryptoJS.MD5(
    CryptoJS.enc.Utf8.parse(
      JSON.parse(localStorage.getItem(SESSION_KEY?.USER_DATA))?.CurrentSecuritykey,
    ),
  );
  key.words.push(key.words[0], key.words[1]);
  return key;
};

export const decrypt = (text) =>
  JSON.parse(
    CryptoJS.TripleDES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(text) },
      getCurrentKey(),
      lockObj,
    ).toString(CryptoJS.enc.Utf8),
  );

export const encrypt = (text) =>
  CryptoJS.TripleDES.encrypt(JSON.stringify(text), getCurrentKey(), lockObj).toString();
