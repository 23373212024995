import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { getRules } from '../../services/dynamicFormHelper';

export const InputField = ({ field, formControl }) => (
  <Controller
    name={field.id}
    rules={getRules(field)}
    defaultValue={field?.value}
    control={formControl.control}
    render={({ field: props, fieldState: { error } }) => (
      <TextField
        {...props}
        fullWidth
        type="text"
        size="small"
        id={field.id}
        error={!!error}
        variant="outlined"
        label={field?.label}
        multiline={field?.multiple}
        rows={field?.multiple ? 3 : 1}
        placeholder={field?.placeholder}
        helperText={error?.message || null}
        style={{ marginTop: field?.prefix ? 8 : 0 }}
      />
    )}
  />
);
