import React from 'react';
import { FormHelperText } from '@mui/material';
import { getRules } from '../../services/dynamicFormHelper';

export const SpanTextInput = ({ field, formControl: { register, getFieldState } }) => (
  <span className="inline-flex w-max flex-col">
    <input
      placeholder={field?.placeholder}
      className="mx-2 rounded border px-1"
      {...register(field?.id, getRules(field))}
      style={{ width: Number(field?.width) || 100 }}
    />
    {getFieldState(field?.id)?.error?.message && (
      <FormHelperText error sx={{ ml: 1 }}>
        {getFieldState(field?.id)?.error?.message}
      </FormHelperText>
    )}
  </span>
);
