export const THEME_COLOR = "#5FA9FF";
export const SECONDARY = "#767676";
export const WHITE = "#FFFFFF";
export const THEME_BLACK = "#5C5C5C";
export const THEME_LIGHT_GRAY = "#ABABAB";
export const LIGHT_GRAY = "#D9D9D9";
export const PH_COLOR = "#BDBDBD";
export const LIGHT_BLACK = "#3D3D3D";
export const LIGHT_BLUE = "#EBF2FF";
export const LIGHTER_BLUE = "#d6e9fc";
export const LIGHTER_ORANGE = "#ffb6ab";
export const HEADER_COLOR = "#F8FAFF";
export const RED_DARK = "#D60031";
export const BLUE_DARK = "#008DE7";
export const LIGHTER_GREEN = "#D1FFE6";
export const LIGHT_GREEN = "#1c9b54";
export const DARK_GREEN = "#306347";
export const BORDER_COLOR = "#D9D9D9";
export const DARK_GRAY = "#DFDFDF";
export const BORDER_LIGHT = "#9e9e9e";
export const GREEN = "green";
export const YELLOW = "yellow";
export const RED = "red";
export const GREEN_LIGHT = "#DBF4DB";
export const YELLOW_LIGHT = "#FFF4E2";
export const RED_LIGHT = "#FFE2E2";
export const BG_YELLOW = "#FFCA63";
export const GRAY = "#979797";
export const BG_GRAY = "#E0E6EB";
export const DARK_BLUE = "#060A23";
export const GRAY_BORDER_COLOR = "#ddd";
export const STEEL_BLUE = "#4682B4";
export const LIGHT_BLUE_BORDER = "#64b6e4";
export const LABEL_GRAY = "#989898";

export const iconColors = {
  red: {
    fill: "#D60031",
    stroke: "#FFE2DD",
  },
  green: {
    fill: "#1c9b54",
    stroke: "#D1FFE6",
  },
  blue: {
    fill: "#008DE7",
    stroke: "#D8EBFF",
    sfill: "#92CEF4",
  },
  gray: {
    fill: "#5C5C5C",
    stroke: "#ffffff",
  },
};
