import * as React from 'react';

const RightArrow = ({ w, h, ...props }) => (
  <svg width={w} height={h} viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1L5 5L1 9"
      stroke="#64748B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RightArrow;
