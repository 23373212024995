import { clearLoginData } from './reducer';
import { clearTagData } from '../tags/reducer';
import { post } from '../../services/ApiServices';
import API_CONSTANT from '../../services/ApiConstant';
import { clearAppData } from '../application/reducer';

const postLoginAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(`${API_CONSTANT.LOGIN}`, data, false)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const ChangePasswordAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(`${API_CONSTANT.SET_PASSWORD}`, data, true)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const LOGOUT = (dispatch) => {
  dispatch(clearAppData());
  dispatch(clearTagData());
  dispatch(clearLoginData());
};

export { postLoginAPI, ChangePasswordAPI, LOGOUT };
