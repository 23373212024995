import React from 'react';
import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';
import { FormHelperText } from '@mui/material';
import { defaultDateFormat } from '../../constant/indxex';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getRules } from '../../services/dynamicFormHelper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export const DateField = ({ field, formControl }) => (
  <Controller
    name={field.id}
    rules={getRules(field)}
    control={formControl.control}
    defaultValue={field?.value ? dayjs(field?.value, field?.format) : dayjs()}
    render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          onClose={onBlur}
          onChange={onChange}
          label={field?.label}
          format={field?.format || defaultDateFormat}
          slotProps={{ textField: { size: 'small', fullWidth: true } }}
        />
        <FormHelperText error>{error?.message}</FormHelperText>
      </LocalizationProvider>
    )}
  />
);
