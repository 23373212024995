import * as React from 'react';

const Search = (props) => (
  <svg
    width={props.w}
    height={props.h}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={'0 0 26 26'}
    {...props}
    className={props.class}
  >
    <path
      d="M11.781 20.313a8.531 8.531 0 1 0 0-17.063 8.531 8.531 0 0 0 0 17.063ZM17.814 17.814l4.936 4.936"
      stroke="#5C5C5C"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Search;
