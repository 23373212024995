import React, { useState } from 'react';
import { Dialog } from '@mui/material';
import { toast } from 'react-toastify';
import { TextBox } from 'devextreme-react';
import icons from '../../config/assets/icons';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import TextArea from 'devextreme-react/text-area';
import { post } from '../../services/ApiServices';
import API_CONSTANT from '../../services/ApiConstant';
import { DOC_NUMBERS, MODE } from '../../services/enum';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DevDropDown } from '../../components/devExtremeFields';
import { SESSION_KEY, TEXT, getFromAsync } from '../../services/utils';
import { decrypt, encrypt } from '../../services/encryptDecryptService';

const AddEditTag = ({ data, isOpen, setIsOpen, getAppDataApi, setIsAppBGDoc }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('640'));

  const [tagText, setTagText] = useState('');
  const [tagSummary, setTagSummary] = useState('');
  const [selectedDocNum, setSelectedDocNum] = useState([]);
  const [selectedToUser, setSelectedToUser] = useState([]);

  const _onAddEditTag = () => {
    const userData = getFromAsync(SESSION_KEY?.USER_DATA);

    const obj = {
      DocumentId: data?.documentId,
      TagText: tagText,
      TagSummary: tagSummary,
      ToUser: selectedToUser?.id,
      DocumentNumber: selectedDocNum?.id,
    };

    const apiObj = {
      AppUserId: userData?.AppUserId,
      Mode: MODE?.WEB_MODE,
      InputText: encrypt(obj),
    };

    post(API_CONSTANT?.ADD_NEW_TAG, apiObj)
      .then((res) => {
        const data = decrypt(res.data.OutPutText);
        if (data?.IsCreated) {
          setTagText('');
          setTagSummary('');
          setIsOpen(false);
          setIsAppBGDoc(false);
          setSelectedDocNum([]);
          setSelectedToUser([]);
          toast.success(data?.Message, { autoClose: 900 });
          setTimeout(() => {
            getAppDataApi();
          }, 1000);
        }
      })
      .catch((e) => {
        toast.error(e?.data?.ErrorMessage || e?.message || String(e));
      });
  };

  const _onClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Dialog
        maxWidth="md"
        open={isOpen}
        fullWidth={true}
        fullScreen={fullScreen}
        onClose={() => _onClose()}
        className="backdrop-blur-[5px]"
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: '15px',
          },
        }}
      >
        <div className="h-full max-h-[550px] overflow-y-auto [&::-webkit-scrollbar]:hidden">
          <div className="border-box-bg-gray sticky top-0 flex justify-between border-b-[1px] bg-white px-5 py-4">
            <div className="m-auto">
              <h2 className="flex items-center text-center text-xl font-medium text-black">
                {t(TEXT?.NEW_TAG)}
              </h2>
            </div>
            <div className="my-auto cursor-pointer" onClick={() => _onClose()}>
              <icons.close w={30} h={30} />
            </div>
          </div>
          <div className="p-4">
            <div className="flex w-full items-center">
              <div className="mr-3 w-1/3">
                <TextBox
                  value={tagSummary}
                  labelMode="floating"
                  label={t(TEXT?.TAG_SUMMARY)}
                  className="!h-[45px] !w-full"
                  onValueChanged={(e) => {
                    setTagSummary(e?.value);
                  }}
                />
              </div>
              <div className="mr-3 w-1/3">
                <DevDropDown
                  height={45}
                  displayValue="user"
                  customImage={false}
                  labelMode="floating"
                  searchEnabled={true}
                  value={selectedToUser}
                  label={t(TEXT?.TO_USER)}
                  data={[{ id: '4044A514-B3CD-46C7-8CB8-129B763E737C', user: 'admin' }]}
                  onSelectionChanged={(e) => {
                    setSelectedToUser(e?.selectedItem);
                  }}
                />
              </div>
              <div className="w-1/3">
                <DevDropDown
                  height={45}
                  data={DOC_NUMBERS}
                  displayValue="name"
                  customImage={false}
                  labelMode="floating"
                  searchEnabled={true}
                  value={selectedDocNum}
                  label={t(TEXT?.DOCUMENT_NUMBER)}
                  onSelectionChanged={(e) => {
                    setSelectedDocNum(e?.selectedItem);
                  }}
                />
              </div>
            </div>
            <div>
              <TextArea
                value={tagText}
                minHeight={100}
                labelMode="floating"
                autoResizeEnabled={true}
                label={t(TEXT?.TAG_TEXT)}
                valueChangeEvent="change"
                onValueChanged={(e) => {
                  setTagText(e?.value);
                }}
              />
            </div>
            <div className="mb-2 mt-5 flex justify-center py-2 text-left sm:mb-5">
              <button
                onClick={() => _onAddEditTag()}
                className="flex w-max items-center justify-center rounded-md bg-light-yellow px-6 py-2"
              >
                <p className="text-sm font-medium text-white sm:text-base">{t(TEXT?.SAVE)}</p>
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddEditTag;
