import './App.css';
import './services/i18n';
import React from 'react';
import { Provider } from 'react-redux';
import Navigation from './route/navigation';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { persistor, store } from './store/store';
import { AppProvider } from './context/appProvider';
import { PersistGate } from 'redux-persist/integration/react';

function App() {
  return (
    <div className="App">
      <AppProvider>
        <ToastContainer />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <div className="flex">
              <div
                className="w-full overflow-y-scroll [&::-webkit-scrollbar]:hidden"
                style={{ height: '100vh' }}
              >
                <div className="w-full">
                  <Navigation />
                </div>
              </div>
            </div>
          </PersistGate>
        </Provider>
      </AppProvider>
    </div>
  );
}

export default App;
