import dayjs from 'dayjs';
import { CreateField } from '.';
import { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Button, Divider, FormGroup } from '@mui/material';
import { AddSharp, RemoveSharp } from '@mui/icons-material';

export function FieldArray({ field, apiCall, formControl, setShowSnack }) {
  const { fields, append, remove } = useFieldArray({
    name: field?.id,
    control: formControl.control,
  });

  const addFields = () =>
    field?.child?.reduce(
      (p, e) => ({
        ...p,
        [String(e?.id)]:
          (e.type === 'date'
            ? field?.value
              ? dayjs(field?.value, field?.format)
              : dayjs()
            : e?.value) || null,
      }),
      {},
    );

  useEffect(() => {
    append(addFields());
    remove(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !field?.child ? null : (
    <div className="mt-3 rounded-lg border p-3">
      <p>{field?.label}</p>
      {fields?.map((item, i) => (
        <FormGroup key={item.id + i}>
          <div className="my-2 flex items-center gap-3">
            <div className="flex w-full flex-col gap-2">
              <CreateField
                {...{ apiCall, formControl, setShowSnack }}
                fields={field?.child?.map((e) => ({ ...e, id: `${field.id}.${i}.${e?.id}` }))}
              />
            </div>
            {!!i && (
              <Button
                size="small"
                variant="contained"
                className="!mt-3 !h-10"
                onClick={() => remove(i)}
              >
                <RemoveSharp />
              </Button>
            )}
          </div>
          {fields?.length !== i + 1 && <Divider />}
        </FormGroup>
      ))}
      <Button className="!mt-1 !h-10 !w-10" variant="contained" onClick={() => append(addFields())}>
        <AddSharp />
      </Button>
    </div>
  );
}
