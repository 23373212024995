import * as React from 'react';
import Images from '../../config/Images';
import IconImage from '../image/IconImage';
import { PH_COLOR } from '../../config/color';
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment } from '@mui/material';

export default function TextInput(props) {
  return (
    <TextField
      {...props}
      id={props?.id}
      type={props?.type}
      value={props?.value}
      label={props?.label}
      variant={props?.variant}
      onChange={props?.onChange}
      fullWidth={props?.fullWidth}
      autoFocus={props?.autoFocus}
      placeholder={props?.placeholder}
      onKeyDownCapture={props?.onKeyDownCapture}
      sx={[{ input: props?.sx, label: { color: PH_COLOR } }]}
      InputProps={
        props?.id === 'password'
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={props?.onShow}>
                    <IconImage name={props.show ? Images.EyeSlash : Images.Eye} isEye />
                  </IconButton>
                </InputAdornment>
              ),
            }
          : { disableUnderline: props?.border === 'true' }
      }
    />
  );
}
