import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import Images from '../../config/Images';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { post } from '../../services/ApiServices';
import { TextArea, TextBox } from 'devextreme-react';
import API_CONSTANT from '../../services/ApiConstant';
import { HeaderSimple } from '../../components/header';
import { DOC_NUMBERS, MODE } from '../../services/enum';
import IconImage from '../../components/image/IconImage';
import { ComponentLoading } from '../../components/loading';
import TagSummary from '../../components/tagSummary/TagSummary';
import { DevDropDown } from '../../components/devExtremeFields';
import { decrypt, encrypt } from '../../services/encryptDecryptService';
import { ROUTE, SESSION_KEY, TEXT, getFromAsync } from '../../services/utils';

const TagDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { tag } = useSelector((state) => state);
  const userData = getFromAsync(SESSION_KEY?.USER_DATA);

  const [tagText, setTagText] = useState('');
  const [tagDetails, setTagDetails] = useState([]);
  const [tagSummary, setTagSummary] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDocNum, setSelectedDocNum] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedToUser, setSelectedToUser] = useState([]);

  useEffect(() => {
    getTagDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTagDetails = () => {
    setIsLoading(true);

    const apiObj = {
      Mode: MODE.WEB_MODE,
      AppUserId: userData?.AppUserId,
      InputText: encrypt({ TagId: tag?.tagData?.TagId }),
    };

    post(API_CONSTANT.TAG_DETAILS, apiObj)
      .then((res) => {
        const data = decrypt(res.data.OutPutText);
        data?.TagStatusList?.forEach((list) => {
          if (data?.TagDetail?.TagStatus?.toLowerCase() === list?.StatusText?.toLowerCase()) {
            setSelectedStatus(list);
          }
        });
        DOC_NUMBERS?.forEach((list) => {
          if (data?.TagDetail?.DocumentNumber?.toLowerCase() === list?.name?.toLowerCase()) {
            setSelectedDocNum(list);
          }
        });
        setTagDetails(data);
        setTagSummary(data?.TagDetail?.TagSummary);
        setSelectedToUser({
          UserId: data?.TagDetail?.ToUserId,
          UserName: data?.TagDetail?.ToUser,
        });
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(e?.data?.ErrorMessage || e?.message || String(e));
      });
  };

  const _onEditTag = () => {
    setIsLoading(true);

    const obj = {
      TagText: tagText,
      TagSummary: tagSummary,
      ToUser: selectedToUser?.UserId,
      DocumentNumber: selectedDocNum?.id,
      TagStatus: selectedStatus?.StatusId,
      TagId: tagDetails?.TagDetail?.TagId,
      DocumentId: tagDetails?.TagDetail?.DocumentId,
    };

    const apiObj = {
      AppUserId: userData?.AppUserId,
      Mode: MODE?.WEB_MODE,
      InputText: encrypt(obj),
    };

    post(API_CONSTANT.UPDATE_TAG, apiObj)
      .then((res) => {
        const data = decrypt(res.data.OutPutText);
        if (data?.IsUpdated) {
          setTagText('');
          setTagSummary('');
          setIsLoading(false);
          setSelectedDocNum([]);
          setSelectedToUser([]);
          setSelectedStatus([]);
          toast.success(data?.Message, { autoClose: 900 });
          setTimeout(() => {
            navigate(ROUTE?.TAG_LIST);
          }, 1000);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(e?.data?.ErrorMessage || e?.message || String(e));
      });
  };

  const getAttachmentDownload = async () => {
    const slug = tagDetails?.TagDetail?.AttachmentFileName.split('.').pop();
    const LINK = `data:${slug};base64`;
    const linkSource = `${LINK},${tagDetails?.TagDetail?.Attachment}`;
    const downloadLink = document.createElement('a');
    const fileName = tagDetails?.TagDetail?.AttachmentFileName;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <div className="h-full w-full">
      <div className="sticky top-0 z-30">
        <HeaderSimple
          // isGoBack={true}
          // onPress={() => navigate(ROUTE?.TAG_LIST)}
          path={ROUTE?.TAG_LIST}
          title={tag?.tagData?.TagNumber + ' : ' + tag?.tagData?.TagSummary}
        />
      </div>

      {isLoading ? (
        <ComponentLoading loading={isLoading} className="bg-white pt-3" />
      ) : (
        <div className="rounded-t-3xl bg-white px-[8%] md:px-[15%] lg:px-[25%]">
          <div className="w-full border-b-[1px] border-b-extra-dark-grey py-2">
            <div className="flex w-full flex-wrap py-1 sm:flex-nowrap sm:py-2">
              <div className="w-full text-left md:sm:w-2/4">
                {tagDetails?.TagDetail?.TagSummary && (
                  <p className="flex-1 flex-col justify-center overflow-hidden text-ellipsis whitespace-nowrap text-sm font-bold sm:w-4/6 sm:text-lg">
                    {t(TEXT?.TAG_SUMMARY)} :
                    <span className="ml-1 text-xs font-normal text-dark-black md:text-base">
                      {tagDetails?.TagDetail?.TagSummary}
                    </span>
                  </p>
                )}

                {tagDetails?.TagDetail?.TagNumber && (
                  <p className="text-sm font-bold sm:text-lg">
                    {t(TEXT?.TAG_NUMBER)} :
                    <span className="ml-1 text-xs font-normal text-dark-black md:text-base">
                      {tagDetails?.TagDetail?.TagNumber}
                    </span>
                  </p>
                )}

                {tagDetails?.TagDetail?.DocumentStatus && (
                  <p className="mr-4 w-max text-sm font-bold text-dark-black sm:text-lg">
                    {t(TEXT?.DOCUMENT_STATUS)} :
                    <span className="ml-1 text-xs font-normal text-dark-black sm:text-base">
                      {tagDetails?.TagDetail?.DocumentStatus}
                    </span>
                  </p>
                )}
              </div>

              <div className="w-full text-right md:w-2/4">
                {tagDetails?.TagDetail?.Created && (
                  <div className="mt-2 flex justify-start sm:justify-end">
                    <p className="whitespace-pre text-sm font-medium sm:text-base">
                      {dayjs(tagDetails?.TagDetail?.Created).format('DD/MM/YYYY')}
                    </p>
                  </div>
                )}

                {tagDetails?.TagDetail?.CreatedBy && (
                  <div className="flex w-full justify-start sm:justify-end">
                    <div className="mt-3 items-center sm:mt-[7px]">
                      <p className="mr-2 text-xs font-medium sm:text-base">
                        {t(TEXT?.CREATED)} {t(TEXT?.BY)}
                      </p>
                    </div>
                    <div className="mt-1 flex items-center">
                      <IconImage name={Images.UserCircle} isEye width={30} height={20} />
                      <p className="ml-2 mt-[0.8px] text-xs font-medium sm:text-lg">
                        {tagDetails?.TagDetail?.CreatedBy}
                      </p>
                    </div>
                  </div>
                )}

                {tagDetails?.TagDetail?.DocumentNumber && (
                  <div className="flex justify-start sm:justify-end">
                    <p className="w-full text-sm font-bold text-dark-black sm:text-lg">
                      {t(TEXT?.DOCUMENT_NUMBER)} :
                      <span className="ml-1 text-xs font-normal text-dark-black md:text-base">
                        {tagDetails?.TagDetail?.DocumentNumber}
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="w-auto py-2 text-center">
            <p className="text-font-black-light w-full text-base font-bold sm:text-xl">
              {t(TEXT?.TAG_DETAILS)}
            </p>
          </div>

          <div className="flex flex-wrap border-t-[1px] border-t-extra-dark-grey py-[14px] sm:flex-nowrap">
            <div className="mr-0 flex w-full flex-col sm:mr-3 md:w-[70%]">
              <div className="w-full">
                <TextBox
                  value={tagSummary}
                  labelMode="floating"
                  label={t(TEXT?.TAG_SUMMARY)}
                  className="!h-[45px] !w-full"
                  onValueChanged={(e) => {
                    setTagSummary(e?.value);
                  }}
                />
              </div>
              <div className="w-full">
                <DevDropDown
                  height={45}
                  customImage={false}
                  labelMode="floating"
                  searchEnabled={true}
                  value={selectedToUser}
                  displayValue="UserName"
                  label={t(TEXT?.TO_USER)}
                  data={tagDetails?.ToUserList}
                  onSelectionChanged={(e) => {
                    setSelectedToUser(e?.selectedItem);
                  }}
                />
              </div>
              <div className="w-full">
                <DevDropDown
                  height={45}
                  customImage={false}
                  labelMode="floating"
                  searchEnabled={true}
                  value={selectedStatus}
                  displayValue="StatusText"
                  label={t(TEXT?.TAG_STATUS)}
                  data={tagDetails?.TagStatusList}
                  onSelectionChanged={(e) => {
                    setSelectedStatus(e?.selectedItem);
                  }}
                />
              </div>
              <div className="w-full">
                <DevDropDown
                  height={45}
                  data={DOC_NUMBERS}
                  displayValue="name"
                  customImage={false}
                  labelMode="floating"
                  searchEnabled={true}
                  value={selectedDocNum}
                  label={t(TEXT?.DOCUMENT_NUMBER)}
                  onSelectionChanged={(e) => {
                    setSelectedDocNum(e?.selectedItem);
                  }}
                />
              </div>
              <div className="w-full">
                <TextArea
                  value={tagText}
                  minHeight={100}
                  labelMode="floating"
                  autoResizeEnabled={true}
                  label={t(TEXT?.TAG_TEXT)}
                  valueChangeEvent="change"
                  onValueChanged={(e) => {
                    setTagText(e?.value);
                  }}
                />
              </div>

              {tagDetails?.TagDetail?.AttachmentFileName && (
                <>
                  <div className="mt-2 w-full border border-gray-200" />
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      getAttachmentDownload();
                    }}
                    className="my-2 flex w-full cursor-pointer items-center rounded-lg bg-dark-grey pl-3"
                  >
                    <div className="flex w-full items-center py-2">
                      <img src={Images?.AttachY} alt="icon" className="mr-2 w-6 md:w-10" />
                      <p className="w-30 mr-2 flex-1 flex-col justify-center overflow-hidden text-ellipsis text-left text-sm ">
                        {tagDetails?.TagDetail?.AttachmentFileName}
                      </p>
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        getAttachmentDownload();
                      }}
                      className="p-2"
                    >
                      <img src={Images?.Download} alt="icon" className="w-5 md:w-7" />
                    </button>
                  </div>
                  <div className="w-full border border-gray-200  sm:w-full" />
                </>
              )}
            </div>

            <div className="mt-2 flex w-full justify-end sm:w-1/2">
              <div className="w-full text-end sm:w-[300px]">
                <TagSummary data={tagDetails?.TagDetail} />
              </div>
            </div>
          </div>
          <div className="mb-2 mt-5 flex justify-center py-2 text-left sm:mb-5">
            <button
              onClick={() => _onEditTag()}
              className="flex w-max items-center justify-center rounded-md bg-light-yellow px-6 py-2"
            >
              <p className="text-sm font-medium text-white sm:text-base">{t(TEXT?.SAVE)}</p>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TagDetails;
