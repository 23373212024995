import React from 'react';
import { Dialog } from '@mui/material';
import NewTag from '../buttons/NewTag';
import { TEXT } from '../../services/utils';
import icons from '../../config/assets/icons';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import TagSummary from '../tagSummary/TagSummary';
import useMediaQuery from '@mui/material/useMediaQuery';

const AppBackGroundDocs = ({
  isOpen,
  appData,
  setIsOpen,
  setAppData,
  setTagData,
  setIsTagOpen,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('640'));

  const _onClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Dialog
        maxWidth="md"
        open={isOpen}
        fullWidth={true}
        fullScreen={fullScreen}
        onClose={() => _onClose()}
        className="backdrop-blur-[5px]"
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: '15px',
          },
        }}
      >
        <div className="h-full max-h-[550px] overflow-y-auto [&::-webkit-scrollbar]:hidden">
          <div className="border-box-bg-gray sticky top-0 z-30 flex justify-between border-b-[1px] bg-white px-5 py-4">
            <div className="m-auto">
              <h2 className="flex items-center text-center text-xl font-medium text-black">
                {t(TEXT?.BACKGROUND_DOCS)}
              </h2>
            </div>
            <div className="my-auto cursor-pointer" onClick={() => _onClose()}>
              <icons.close w={30} h={30} />
            </div>
          </div>
          <div className="p-4">
            {appData?.BackGroundDocs?.map((list) => {
              return (
                <div key={list?.ApplicationBackGroundDocsId}>
                  <div className="flex flex-wrap sm:flex-nowrap">
                    <div className="w-full sm:w-1/2">
                      <p className="pb-1">
                        <span className="text-lg font-medium text-font-black">
                          {t(TEXT?.KEYWORD)} :
                        </span>
                        <span className="text-base font-normal"> {list?.Keyword}</span>
                      </p>
                      <p className="pb-1">
                        <span className="mr-1 text-lg font-medium text-font-black">
                          {t(TEXT?.URL)} :
                        </span>
                        <span
                          onClick={() => {
                            window.open(list?.URL, '_blank');
                          }}
                          className="cursor-pointer text-base font-normal text-light-blue underline"
                        >
                          {list?.URL}
                        </span>
                      </p>
                    </div>
                    <div className="my-auto w-full sm:w-1/2">
                      {list?.TagId !== 0 ? (
                        <TagSummary data={list} />
                      ) : (
                        <div className="flex w-full items-center justify-center sm:justify-end">
                          <NewTag
                            handleClick={() => {
                              setTagData({
                                documentId: list?.ApplicationBackGroundDocsId,
                                tagId: list?.TagId,
                              });
                              setIsTagOpen(true);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="my-1 border-[1px] border-dark-grey-border" />
                  <div className="border-b-2 border-dark-grey-border">
                    <span className="text-lg font-medium text-font-black">
                      {t(TEXT?.DATA_TEXT)} :
                      <div
                        dangerouslySetInnerHTML={{
                          __html: list?.DataText?.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                        }}
                        className="mb-2 text-base font-normal"
                      />
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AppBackGroundDocs;
