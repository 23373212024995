import React from 'react';
import { Controller } from 'react-hook-form';
import { getRules } from '../../services/dynamicFormHelper';
import { Checkbox, FormControlLabel, FormGroup, FormHelperText, FormLabel } from '@mui/material';

export function CheckboxField({ field, formControl }) {
  return (
    !!field?.options?.length && (
      <Controller
        name={field.id}
        rules={getRules(field)}
        control={formControl.control}
        render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
          <FormGroup onBlur={onBlur}>
            <FormLabel error={!!error}>{field.label}</FormLabel>
            {field?.options?.map((e, i) => (
              <FormControlLabel
                label={e}
                key={field.id + i}
                control={
                  <Checkbox
                    checked={value?.includes(e)}
                    onChange={({ target: { checked } }) =>
                      onChange(checked ? [...(value || []), e] : value.filter((_e) => _e !== e))
                    }
                  />
                }
              />
            ))}
            <FormHelperText error={!!error}>{error?.message}</FormHelperText>
          </FormGroup>
        )}
      />
    )
  );
}
