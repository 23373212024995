import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  docURL: {},
  docURLSList: [],
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDocURL(state, action) {
      state.docURL = action.payload;
    },
    setDocURLSList(state, action) {
      state.docURLSList = action.payload;
    },
    clearAppData(state) {
      state.docURL = {};
      state.docURLSList = [];
    },
  },
});

export const { setDocURL, setDocURLSList, clearAppData } = appSlice.actions;
export default appSlice;
