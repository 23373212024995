import React from 'react';
import { useTranslation } from 'react-i18next';
import { TEXT } from '../../services/utils';
import TextInput from '../inputs/TextInput';

const SearchBar = (props) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full items-center rounded-lg bg-extra-light-blue p-1 px-3 ">
      {props.LeftIcon && <props.LeftIcon />}
      <TextInput
        id="email"
        border="true"
        fullWidth={true}
        sx={{ p: 1.189 }}
        variant="standard"
        value={props?.searchValue}
        onChange={props?.onChange}
        autoFocus={props?.autoFocus}
        onKeyDownCapture={props?.onKeyDownCapture}
        placeholder={
          props?.placeholder !== undefined ? props?.placeholder : t(TEXT?.SEARCH_IN_EMAILS)
        }
      />
      {props.RightIcon && <props.RightIcon />}
    </div>
  );
};

export default SearchBar;
