const Images = {
  DOC: require('./assets/images/Doc.png'),
  Eye: require('./assets/images/Eye.png'),
  PDF: require('./assets/images/Pdf.png'),
  PPT: require('./assets/images/PPT.png'),
  TXT: require('./assets/images/Txt.png'),
  Logo: require('./assets/images/Logo.png'),
  EXCEL: require('./assets/images/Excel.png'),
  Power: require('./assets/images/Power.png'),
  Search: require('./assets/images/Search.png'),
  Polygon: require('./assets/images/Polygon.png'),
  AttachY: require('./assets/images/AttachY.png'),
  EyeSlash: require('./assets/images/EyeSlash.png'),
  UserCircle: require('./assets/images/UserCircle.png'),
  Attachment: require('./assets/images/Attachment.png'),
  Download: require('./assets/images/DownloadSimple.png'),
  UnderConstruction: require('./assets/images/UnderConstruction.png'),
};

export default Images;
