/* eslint-disable import/no-anonymous-default-export */

import {
  THEME_LIGHT_GRAY,
  LIGHT_BLACK,
  THEME_BLACK,
  WHITE,
  LIGHT_GRAY,
} from "../config/color";
import {
  FS12,
  FS14B,
  FS16,
  FS16B,
  FS17,
  FS18,
  FS18B,
  FS20,
  FS20B,
  FS20M,
  FS23,
  FS25B,
  FS28B,
  FS35B,
} from "./../config/typography";

const jc = { justifyContent: "center" };
const ac = { alignItems: "center" };
const jce = { justifyContent: "flex-end" };
const jcs = { justifyContent: "flex-start" };
const aie = { alignItems: "flex-end" };

const dc = { display: "contents" };
const df = { display: "flex" };

const tas = { textAlign: "start" };
const ase = { alignSelf: "end" };

export default {
  mainView: { ...ac, height: "100vh" },
  mainHeight: { height: "100vh" },
  subView: { ...jc, ...ac, ...dc },
  center: { ...jc, ...ac },
  ac: { ...ac },
  start: { ...tas },
  jc: { ...jc },
  jcStart: { ...jcs },
  jcEnd: { ...jce },
  flex: { ...df },
  aiEnd: { ...aie },
  textBtnStyle: { mb: 5, ...aie, pl: 2, pr: 2 },
  mainBtnView: { ...jc, ...ac, mb: 2 },
  inputSubView: { ...ac, mb: 2 },
  mb2: { mb: 2 },
  imageStyle: { width: "70%", height: "100%" },
  fs20MB: { ...FS20M, color: LIGHT_BLACK },

  fs12B: { ...FS12, color: THEME_BLACK },
  fs16G: { ...FS16, color: LIGHT_GRAY },
  fs18B: { ...FS18, color: THEME_BLACK },
  fs18W: { ...FS18, color: WHITE },
  fs17B: { ...FS17, color: THEME_BLACK },
  fs20B: { ...FS20, color: THEME_BLACK },
  fs20G: { ...FS20, color: THEME_LIGHT_GRAY },

  fs25BB: { ...FS25B, color: THEME_BLACK },
  fs16BB: { ...FS16B, color: THEME_BLACK },
  fs18BB: { ...FS18B, color: THEME_BLACK },
  fs20BB: { ...FS20B, color: THEME_BLACK },
  fs28BB: { ...FS28B, color: THEME_BLACK },
  fs35BB: { color: THEME_BLACK, ...FS35B },
  fs23: { color: THEME_BLACK, ...FS23 },
  fs14BW: { color: WHITE, ...FS14B },
  plr2: { pl: 2, pr: 2 },
  pr: { pr: 2 },
  asEnd: { ...ase },
  textEllipsis: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
};
