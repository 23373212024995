// FIXME: we are now using api to convert url to pdf
// export const regExp = {
//   removeStyle: /<style([\S\s]*?)style>/gim,
//   link: /<link /gim,
// };

import dayjs from 'dayjs';

export const defaultDateFormat = 'MM/DD/YYYY';

export const getFormattedDate = (val, format) => dayjs(val).format(format || defaultDateFormat);
