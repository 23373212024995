import React, { useState } from 'react';
import { Avatar } from '@mui/material';
import Images from '../../config/Images';
import { TEXT } from '../../services/utils';
import { useTranslation } from 'react-i18next';
import TagSummaryModal from '../modal/TagSummaryModal';

const TagSummary = ({ data }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-cascading-white w-full rounded border">
      <p className="cursor-pointer p-[2px] text-center text-lg font-normal text-main underline">
        {t(TEXT?.TAG_NUMBER)} - {data?.TagNumber}
      </p>

      <div className="text-medium-blue bg-white px-2 text-left">
        <p>
          {t(TEXT?.TAG_STATUS)} : {data?.TagStatus}
        </p>
        <p>
          {t(TEXT?.TAG_SUMMARY)} : {data?.TagSummary}
        </p>
      </div>

      {data?.TagAttachmentFileName && (
        <div className="m-auto flex w-full items-center justify-center border-t-[1px] border-t-extra-dark-grey bg-white">
          <img src={Images?.Attachment} alt="attachment" width={20} height={20} className="mx-1" />

          <p className="text-armor-black cursor-pointer p-1 text-center text-base font-medium underline">
            {data?.TagAttachmentFileName ? data?.TagAttachmentFileName : 'test.png'}
          </p>
        </div>
      )}

      <div className="w-full border-b-[1px] bg-dark-grey-bg sm:block" />
      <div className="bg-white">
        {data?.TagContent?.slice(0, 3)?.map((e, i) => {
          let Body = { __html: `<div className="">${e?.Body} </div>` };

          return (
            <div key={i}>
              <div className="px-2 py-1">
                <div className="flex items-center">
                  {e?.AddUser !== null && (
                    <Avatar
                      alt={e?.AddUser?.toUpperCase()}
                      src="/static/images/avatar/1.jpg"
                      sx={{
                        width: 24,
                        height: 24,
                        py: '18px',
                        px: '18px',
                        fontSize: '1.3rem',
                      }}
                    />
                  )}
                  <p className="px-2 text-base font-medium text-light-black">
                    {e?.AddUser && e?.AddUser}
                  </p>
                  <p className="text-sm font-medium text-extra-dark-text">
                    {e?.AddDateTimeText && e?.AddDateTimeText}
                  </p>
                </div>
                <div className="text-left text-sm font-normal text-light-black-text">
                  {e?.Body && (
                    <div
                      dangerouslySetInnerHTML={Body}
                      className="whitespace-pre-wrap text-left font-normal text-dark-grey-text"
                      id="section-to-print"
                    />
                  )}
                </div>
              </div>
              <div className="w-full border-b-[1px] bg-dark-grey-border sm:block" />
            </div>
          );
        })}
        <div onClick={() => setIsOpen(true)}>
          <p className="cursor-pointer p-[2px] text-center text-sm font-normal text-main">
            {t(TEXT?.READ_MORE)}
          </p>
        </div>
      </div>

      {isOpen && <TagSummaryModal data={data} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </div>
  );
};

export default TagSummary;
