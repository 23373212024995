import React from 'react';
import images from '../../config/Images';
import icons from '../../config/assets/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFromAsync, removeFromAsync, ROUTE, SESSION_KEY, TEXT } from '../../services/utils';

export default function HeaderSimple({ home, title, cs, path, isGoBack, onPress, ...props }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userData = getFromAsync(SESSION_KEY.USER_DATA);

  const handleLogout = () => {
    navigate(ROUTE?.LOGIN);
    removeFromAsync(SESSION_KEY.USER_DATA);
    removeFromAsync(SESSION_KEY.APP_TYPE_ID);
  };

  return (
    <div className="sticky top-0 z-30 flex h-24 items-center bg-ghost-white px-[1.5%] text-lg font-bold md:text-2xl">
      <div className="flex w-3/5 items-center">
        <icons.back
          w={30}
          h={30}
          class="mr-[2%] cursor-pointer"
          onPress={() => {
            if (home) {
              navigate(ROUTE?.DASHBOARD);
            } else {
              navigate(path);
            }
            // if (path) {
            //   navigate(ROUTE.DASHBOARD);
            // } else {
            //   home ? navigate(ROUTE.DASHBOARD) : navigate(ROUTE.GO_BACK);
            // }
          }}
          // onPress={() => {
          //   if (path) {
          //     navigate(ROUTE.DASHBOARD);
          //   } else {
          //     home ? navigate(ROUTE.DASHBOARD) : navigate(ROUTE.GO_BACK);
          //   }
          // }}
        />
        <p className="flex-1 flex-col overflow-hidden text-ellipsis whitespace-nowrap text-left text-lg sm:w-4/6 md:text-2xl">
          {title}
        </p>
      </div>
      <div className="flex w-2/5 items-center justify-end">
        <p className="text-font-black-light mr-5  hidden whitespace-pre text-sm font-semibold md:block md:text-xl">
          {userData?.WelcomeMessage}!
        </p>
        <button className="flex" onClick={() => handleLogout()}>
          <img src={images.Power} alt="icon" className="w-5 md:w-7" />
          <p className="text-font-red ml-2 hidden text-sm  font-medium md:block md:text-xl">
            {t(TEXT?.LOGOUT)}
          </p>
        </button>
      </div>

      {isGoBack && (
        <div className="absolute left-0 top-0 flex h-24 w-[30px] cursor-pointer items-center px-[1.5%] text-lg font-bold md:text-2xl ">
          <div className="flex w-[30px] items-center">
            <icons.back w={30} h={30} class="mr-[2%]" onPress={() => onPress()} />
          </div>
        </div>
      )}
    </div>
  );
}
