import Back from './back';
import Arrow from './arrow';
import Close from './close';
import Search from './search';
import RightArrow from './dashboard/rightArrow';

const icons = {
  back: Back,
  arrow: Arrow,
  close: Close,
  search: Search,
  rightArrow: RightArrow,
};

export default icons;
