import React from 'react';
import { Dialog } from '@mui/material';
import { Avatar } from '@mui/material';
import { TEXT } from '../../services/utils';
import icons from '../../config/assets/icons';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const TagSummaryModal = ({ data, isOpen, setIsOpen }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('640'));

  return (
    <div>
      <Dialog
        maxWidth="md"
        open={isOpen}
        fullWidth={true}
        fullScreen={fullScreen}
        className="backdrop-blur-none"
        onClose={() => setIsOpen(false)}
        aria-labelledby="responsive-dialog-title"
        contentstyle={{
          width: '100%',
          maxWidth: '900px',
        }}
        PaperProps={{
          sx: {
            borderRadius: '20px',
          },
        }}
      >
        <div className="scroll-component h-full max-h-full overflow-y-auto sm:!h-[500px] sm:max-h-[500px] [&::-webkit-scrollbar]:hidden">
          <div className="sticky top-0 z-30 flex justify-between border-b-[1px] border-dark-grey bg-white px-5 py-4">
            <div className="m-auto">
              <h2 className=" flex items-center text-center text-xl font-medium">
                {t(TEXT?.TAG_SUMMARY)}
              </h2>
            </div>
            <div className="my-auto cursor-pointer" onClick={() => setIsOpen(false)}>
              <icons.close w={30} h={30} />
            </div>
          </div>
          <div className="w-full border-b-[1px] bg-dark-grey-border sm:block" />
          <div className="flex w-full items-center justify-between bg-cascading-white px-8 py-3 text-base font-normal text-medium-blue">
            <p>
              {t(TEXT?.TAG_STATUS)} : {data?.TagStatus}
            </p>
            <p>
              {t(TEXT?.TAG_SUMMARY)} : {data?.TagSummary}
            </p>
          </div>
          {data?.TagContent
            ? data?.TagContent?.map((f, i) => {
                let TagSummaryBody = {
                  __html: `<div className="">${f?.Body} </div>`,
                };

                return (
                  <div key={i}>
                    <div className="px-7 py-3">
                      <div className="flex items-center">
                        {f?.AddUser !== null && (
                          <Avatar
                            alt={f?.AddUser.toUpperCase()}
                            src="/static/images/avatar/1.jpg"
                            sx={{
                              width: 24,
                              height: 24,
                              py: '18px',
                              px: '18px',
                              fontSize: '1.3rem',
                            }}
                          />
                        )}

                        <p className="px-2 text-base font-medium text-light-black-text">
                          {f?.AddUser}
                        </p>
                        <p className="text-sm font-medium text-extra-dark-text">
                          {f?.AddDateTimeText}
                        </p>
                      </div>
                      <div className="mt-1 text-left text-sm font-normal text-light-black-text">
                        {f?.Body && (
                          <div
                            dangerouslySetInnerHTML={TagSummaryBody}
                            className="whitespace-pre-wrap text-left font-normal text-dark-grey-text"
                            id="section-to-print"
                          />
                        )}
                      </div>
                    </div>
                    <div className="w-full border-b-[1px] bg-dark-grey-bg sm:block" />
                  </div>
                );
              })
            : null}
        </div>
      </Dialog>
    </div>
  );
};

export default TagSummaryModal;
