import { Tooltip } from "@mui/material";
import * as React from "react";

export default function IconImage({
  name,
  isEye,
  width,
  height,
  svg = false,
  tooltip,
}) {
  const css = {
    marginTop: isEye ? 0 : 20,
    marginRight: isEye ? 0 : 10,
  };

  return (
    <Tooltip title={tooltip}>
      <img
        src={svg ? name?.default : name}
        alt="ReactImage"
        width={width ? width : 20}
        height={height ? height : ""}
        style={css}
      />
    </Tooltip>
  );
}
