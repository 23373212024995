import axios from 'axios';
import API_CONSTANT from './ApiConstant';
import { SESSION_KEY, getFromAsync } from './utils';

const api = axios.create({
  delayed: false,
  baseURL: API_CONSTANT.BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});

const getHeader = (isHeader) =>
  isHeader
    ? {
        headers: {
          Authorization: `Bearer ${getFromAsync(SESSION_KEY.USER_DATA)?.ReturnToken}`,
        },
      }
    : {};

export const get = async (endPoint, isHeader = true) => {
  return new Promise((resolve, reject) => {
    api
      .get(endPoint, getHeader(isHeader))
      .then((res) => (res.data.ErrorCode === 0 ? resolve(res) : reject(res)))
      .catch((err) => reject(err));
  });
};

export const post = async (endPoint, data, isHeader = true) => {
  return new Promise((resolve, reject) => {
    api
      .post(endPoint, data, getHeader(isHeader))
      .then((res) => (res?.data?.ErrorCode === 0 && res !== null ? resolve(res) : reject(res)))
      .catch((err) => reject(err));
  });
};
