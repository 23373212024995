import React from 'react';
import { CircularProgress } from '@mui/material';

export const PageLoading = ({ loading }) =>
  !!loading && (
    <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center bg-black/30">
      <CircularProgress />
    </div>
  );

export const ComponentLoading = ({ loading, className }) =>
  !!loading && (
    <div className={'flex items-center justify-center ' + className}>
      <CircularProgress />
    </div>
  );
