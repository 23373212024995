import React from 'react';
import { TEXT } from '../../services/utils';
import { useTranslation } from 'react-i18next';

const NewTag = ({ handleClick }) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={handleClick}
      className="flex w-max items-center justify-center rounded-md bg-light-yellow px-4 py-2"
    >
      <p className="text-lg font-medium text-white">{t(TEXT?.NEW_TAG)}</p>
    </button>
  );
};

export default NewTag;
