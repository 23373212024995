import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import images from '../../config/Images';
import { useDispatch } from 'react-redux';
import { MODE } from '../../services/enum';
import { useTranslation } from 'react-i18next';
import { post } from '../../services/ApiServices';
import NewTag from '../../components/buttons/NewTag';
import API_CONSTANT from '../../services/ApiConstant';
import { HeaderSimple } from '../../components/header';
import { ComponentLoading } from '../../components/loading';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import TagSummary from '../../components/tagSummary/TagSummary';
import TagSummaryModal from '../../components/modal/TagSummaryModal';
import { decrypt, encrypt } from '../../services/encryptDecryptService';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { setDocURL, setDocURLSList } from '../../store/application/reducer';
import DataGrid, { Pager, Paging, Column } from 'devextreme-react/data-grid';
import { AddEditTag, AppBackGroundDocs, DownloadProcess } from '../../components/modal';
import { ROUTE, SESSION_KEY, TEXT, getFromAsync, getUrlParams } from '../../services/utils';

const AppDetails = ({ value, label }) => {
  const { t } = useTranslation();

  return (
    <div className="flex pb-2">
      <div className="w-36 text-left sm:w-48">
        <p className="text-sm text-font-grey sm:text-base">{label}</p>
      </div>
      <div className="mx-5 border-r-2" />
      <div className="w-2/4 text-left">
        <p className="text-sm font-medium sm:text-base">{value || t(TEXT?.DASH)}</p>
      </div>
    </div>
  );
};

const ApplicationDetails = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const path = useResolvedPath();
  const navigate = useNavigate();

  const userData = getFromAsync(SESSION_KEY.USER_DATA);
  const paramData = getUrlParams({ data: path.pathname.replace(`${ROUTE?.APP_DETAILS}/`, '') });
  const appData = decrypt(paramData);

  const [isOpen, setIsOpen] = useState(false);
  const [tagData, setTagData] = useState(null);
  const [isTagOpen, setIsTagOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isAppBGDoc, setIsAppBGDoc] = useState(false);
  const [appBGDocData, setAppBgDocData] = useState([]);
  const [appDocSummary, setAppDocSummary] = useState([]);
  const [applicationData, setApplicationData] = useState(null);

  useEffect(() => {
    getAppData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAppData = () => {
    setIsLoading(true);

    const apiObj = {
      AppUserId: userData?.AppUserId,
      InputText: encrypt({ ApplicationId: appData?.data }),
      Mode: MODE.WEB_MODE,
    };

    post(API_CONSTANT.APPLICATION_DETAILS, apiObj)
      .then((res) => {
        const data = decrypt(res.data.OutPutText);
        dispatch(setDocURLSList(data));
        setApplicationData(data);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(e?.data?.ErrorMessage || e?.message || String(e));
      });
  };

  const _onUpdateStatus = () => {
    setIsLoading(true);

    const apiObj = {
      AppUserId: userData?.AppUserId,
      InputText: encrypt({
        ApplicationId: appData?.data,
        ButtonValue: applicationData?.ButtonValue,
      }),
      Mode: MODE.WEB_MODE,
    };

    post(API_CONSTANT.UPDATE_APPLICATION_STATUS, apiObj)
      .then((res) => {
        const data = decrypt(res.data.OutPutText);
        if (data?.IsUpdated) {
          toast.success(data?.Message || t(TEXT?.APP_DATA_UPDATE_MESSAGE), { autoClose: 1000 });
          setTimeout(() => {
            navigate(ROUTE?.DASHBOARD);
          }, 2000);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(e?.data?.ErrorMessage || e?.message || String(e));
      });
  };

  const getDocument = (val) => {
    setIsDownload(true);

    const apiObj = {
      AppUserId: userData?.AppUserId,
      Mode: MODE?.WEB_MODE,
      InputText: encrypt({ DocumentId: val?.DocumentId }),
    };

    post(API_CONSTANT.SHOW_APPLICATION_DOCUMENT, apiObj)
      .then((res) => {
        if (res?.data?.IsSuccess) {
          const data = decrypt(res?.data?.OutPutText);
          if (data) {
            setIsDownload(false);
            const slug = data?.FileName.split('.').pop();
            const LINK = `data:${slug};base64`;
            const linkSource = `${LINK},${data?.DocumentBase64String}`;
            const downloadLink = document.createElement('a');
            const fileName = data?.FileName;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
          }
        }
      })
      .catch((e) => {
        setIsDownload(false);
        toast.error(e.message || e?.response?.data?.Message);
      });
  };

  const renderMarkKeywordList = ({ data }) => {
    return (
      <div className="cursor-pointer border-b-[1px] border-light-blue-border px-2 py-3">
        <div className="flex items-center justify-between text-base font-medium text-black">
          <p>{data?.Keyword}</p>
          <p>{data?.Added}</p>
        </div>
      </div>
    );
  };

  const renderAppBackGroundData = ({ data }) => {
    return (
      <div className="cursor-pointer border-b-[1px] border-light-blue-border px-2 py-1">
        <div className="flex items-center justify-between text-base font-medium text-black">
          <div
            onClick={(e) => {
              e.stopPropagation();
              setIsAppBGDoc(true);
              setAppBgDocData(data);
            }}
          >
            <p className="text-sm min-[1025px]:text-base">
              <span>{data?.Background}</span>
            </p>
            <p className="text-sm min-[1025px]:text-base">
              <span>{data?.MarkKeyword}</span>
            </p>
          </div>

          <div>
            {data?.TagId !== 0 ? (
              <p
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(true);
                  setAppDocSummary(data);
                }}
                className="w-max cursor-pointer text-sm font-medium text-light-blue underline min-[1025px]:text-base"
              >
                {t(TEXT?.TAG_SUMMARY)}
              </p>
            ) : (
              <div className="flex w-full justify-center sm:justify-normal">
                <NewTag
                  handleClick={() => {
                    setTagData({ documentId: data?.AppBackgroundId, tagId: data?.TagId });
                    setIsTagOpen(true);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="h-full w-full bg-ghost-white">
      <HeaderSimple title={t(TEXT?.APPLICATION_DETAILS)} home />

      <div className="bg-white">
        {isLoading ? (
          <ComponentLoading loading={isLoading} className="pt-3" />
        ) : (
          <>
            <div className="rounded-t-3xl bg-white px-8 pt-2 sm:pt-5">
              <div className="flex flex-wrap items-center justify-between border-b-2 lg:flex-nowrap">
                <div>
                  <AppDetails
                    label={t(TEXT?.APPLICATION_NUMBER)}
                    value={applicationData?.ApplicationNumber}
                  />
                  <AppDetails label={t(TEXT?.ADDED_BY)} value={applicationData?.AddUser} />
                  <AppDetails label={t(TEXT?.EMAIL)} value={applicationData?.Email} />
                  <AppDetails label={t(TEXT?.CITY)} value={applicationData?.City} />
                  <AppDetails label={t(TEXT?.MARK_TEXT)} value={applicationData?.MarkText} />
                  <AppDetails label={t(TEXT?.OWNER_NAME)} value={applicationData?.OwnerName} />
                  <AppDetails
                    label={t(TEXT?.DEFAULT_DEADLINE)}
                    value={applicationData?.RegularDeadline}
                  />
                  <AppDetails
                    label={t(TEXT?.SEARCH_FOR)}
                    value={applicationData?.SearchFor?.join(', ')}
                  />
                  <AppDetails
                    label={t(TEXT?.REQUESTED_DEADLINE)}
                    value={
                      applicationData?.ExpressDeadline && dayjs(applicationData?.ExpressDeadline)
                    }
                  />
                </div>

                <div className="my-3 w-full lg:my-0 lg:w-auto">
                  {applicationData?.TagId !== 0 ? (
                    <TagSummary data={applicationData} />
                  ) : (
                    <div className="flex w-full justify-center sm:justify-normal">
                      <NewTag
                        handleClick={() => {
                          setTagData({
                            documentId: applicationData?.ApplicationId,
                            tagId: applicationData?.TagId,
                          });
                          setIsTagOpen(true);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {applicationData?.ListOfURLs && applicationData?.ListOfURLs?.length > 0 && (
              <div className="mx-8 flex w-[-webkit-fill-available] items-center gap-5 overflow-x-auto overscroll-x-auto">
                {applicationData?.ListOfURLs?.map((ele) => {
                  return (
                    <div className="h-[400px] w-[600px] border-2 border-y-0">
                      <DocViewer
                        documents={[
                          {
                            uri: API_CONSTANT?.BASE_URL + 'api/' + ele?.FileURL,
                          },
                        ]}
                        pluginRenderers={DocViewerRenderers}
                      />
                    </div>
                  );
                })}
              </div>
            )}

            <div className="mx-8 border-b-2" />

            <section className="body-font text-gray-600">
              <div className="container mx-auto px-5 py-8">
                <div className="-m-4 flex flex-wrap">
                  <div className="p-4 md:w-1/2 xl:w-1/4">
                    <div className="rounded-lg bg-grey p-6">
                      <div className="w-full rounded-lg bg-extra-light-blue px-2 py-5">
                        <p className="text-center text-base font-semibold text-light-blue">
                          {t(TEXT?.MARK_KEYWORD_LIST)}
                        </p>
                      </div>

                      {applicationData?.MarkKeywordList !== null &&
                      applicationData?.MarkKeywordList?.length !== 0 ? (
                        <DataGrid
                          showColumnHeaders={false}
                          className="application-datagrid"
                          dataSource={applicationData?.MarkKeywordList}
                        >
                          <Paging defaultPageSize={8} />
                          <Pager
                            visible={true}
                            displayMode={'compact'}
                            showNavigationButtons={true}
                          />
                          <Column
                            width={'100%'}
                            alignment="left"
                            allowEditing={false}
                            cellRender={renderMarkKeywordList}
                          />
                        </DataGrid>
                      ) : (
                        <div className="flex h-full min-h-[506px] w-full items-center justify-center">
                          <p className="text-base font-normal sm:text-lg sm:font-semibold">
                            {t(TEXT?.NO_DATA_FOUND)}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="p-4 md:w-1/2 xl:w-1/4">
                    <div className="rounded-lg bg-grey p-6">
                      <div className="w-full rounded-lg bg-extra-light-blue px-2 py-5">
                        <p className="text-center text-base font-semibold text-light-blue">
                          {t(TEXT?.APP_BACKGROUND)}
                        </p>
                      </div>

                      {applicationData?.AppBackGround !== null &&
                      applicationData?.AppBackGround?.length !== 0 ? (
                        <DataGrid
                          showColumnHeaders={false}
                          className="application-datagrid"
                          dataSource={applicationData?.AppBackGround}
                        >
                          <Paging defaultPageSize={8} />
                          <Pager
                            visible={true}
                            displayMode={'compact'}
                            showNavigationButtons={true}
                          />
                          <Column
                            width={'100%'}
                            alignment="left"
                            allowEditing={false}
                            cellRender={renderAppBackGroundData}
                          />
                        </DataGrid>
                      ) : (
                        <div className="flex h-full min-h-[506px] w-full items-center justify-center">
                          <p className="text-base font-normal sm:text-lg sm:font-semibold">
                            {t(TEXT?.NO_DATA_FOUND)}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="p-4 md:w-1/2 xl:w-1/4">
                    <div className="h-full rounded-lg bg-grey p-6">
                      <div className="w-full rounded-lg bg-extra-light-blue px-2 py-5">
                        <p className="text-center text-base font-semibold text-light-blue">
                          {t(TEXT?.DOCUMENTS)}
                        </p>
                      </div>

                      {applicationData?.DocumentList !== null &&
                      applicationData?.DocumentList?.length !== 0 ? (
                        <div className="h-full max-h-[506px] w-full cursor-pointer overflow-y-auto pb-[4px] [&::-webkit-scrollbar]:hidden">
                          {applicationData?.DocumentList?.map((d) => {
                            const ext = d?.FileName.split('.').pop();
                            const truncateText =
                              d?.FileName?.length > 20
                                ? d?.FileName.substring(0, 20) + '...' + ext
                                : d?.FileName;

                            return (
                              <div
                                key={d?.DocumentId}
                                className="flex w-full items-center border-b border-b-dark-grey-border"
                              >
                                <div
                                  key={d?.FileName}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    getDocument(d);
                                  }}
                                  className="m-2 ml-0 flex w-full cursor-pointer items-center rounded-lg bg-dark-grey pl-3"
                                >
                                  <div className="flex max-h-[48px] w-full items-center py-2">
                                    <img
                                      alt="icon"
                                      src={images?.AttachY}
                                      className="mr-2 w-6 md:w-8"
                                    />
                                    <p className="mr-2 w-32 flex-1 flex-col justify-center overflow-hidden text-ellipsis whitespace-normal break-words text-left text-sm sm:text-xs">
                                      {truncateText}
                                    </p>
                                  </div>
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      getDocument(d);
                                    }}
                                    className="w-7 pr-2"
                                  >
                                    <img
                                      src={images?.Download}
                                      alt="icon"
                                      className="w-5 md:w-[40px]"
                                    />
                                  </button>
                                </div>
                                <div className="w-max">
                                  {d?.TagId !== 0 ? (
                                    <p
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setIsOpen(true);
                                        setAppDocSummary(d);
                                      }}
                                      className="w-max cursor-pointer text-sm font-medium text-light-blue underline min-[1025px]:text-base"
                                    >
                                      {t(TEXT?.TAG_SUMMARY)}
                                    </p>
                                  ) : (
                                    <div className="flex w-full justify-center sm:justify-normal">
                                      <NewTag
                                        handleClick={() => {
                                          setTagData({
                                            documentId: d?.DocumentId,
                                            tagId: d?.TagId,
                                          });
                                          setIsTagOpen(true);
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="flex h-full max-h-[506px] w-full items-center justify-center">
                          <p className="text-base font-normal sm:text-lg sm:font-semibold">
                            {t(TEXT?.NO_DATA_FOUND)}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="p-4 md:w-1/2 xl:w-1/4">
                    <div className="h-full rounded-lg bg-grey p-6">
                      <div className="w-full rounded-lg bg-extra-light-blue px-2 py-5">
                        <p className="text-center text-base font-semibold text-light-blue">
                          {t(TEXT?.URLS)}
                        </p>
                      </div>

                      {applicationData?.ListOfURLs !== null &&
                      applicationData?.ListOfURLs?.length !== 0 ? (
                        <div className="h-full max-h-[506px] overflow-y-auto rounded-xl [&::-webkit-scrollbar]:hidden">
                          {applicationData?.ListOfURLs?.map((list, i) => {
                            const substringText = list?.FileURL.substring(
                              list?.FileURL.lastIndexOf('/') + 1,
                            );
                            const ext = substringText.split('.').pop();
                            const truncateText =
                              substringText?.length > 20
                                ? substringText.substring(0, 20) + '...' + ext
                                : substringText;

                            return (
                              <div
                                key={i}
                                onClick={() => {
                                  dispatch(setDocURL(list));
                                  window.open(ROUTE?.GOOGLE_DOC_VIEW, '_blank');
                                }}
                                className="mb-2 w-full cursor-pointer border-b border-b-dark-grey-border hover:rounded-lg hover:bg-white"
                              >
                                <p className="overflow-hidden text-ellipsis whitespace-normal break-words p-2 text-left text-base font-medium text-black">
                                  {truncateText}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="flex h-full max-h-[506px] w-full items-center justify-center">
                          <p className="text-base font-normal sm:text-lg sm:font-semibold">
                            {t(TEXT?.NO_DATA_FOUND)}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {applicationData?.ButtonText && (
              <div className="mb-2 mt-5 flex justify-center py-2 text-left sm:mb-5">
                <button
                  onClick={() => _onUpdateStatus()}
                  className="flex w-max items-center justify-center rounded-md bg-light-yellow px-6 py-2"
                >
                  <p className="break-words text-sm font-medium text-white sm:text-base">
                    {applicationData?.ButtonText}
                  </p>
                </button>
              </div>
            )}
          </>
        )}
      </div>

      {isTagOpen && (
        <AddEditTag
          data={tagData}
          isOpen={isTagOpen}
          setIsOpen={setIsTagOpen}
          getAppDataApi={getAppData}
          setIsAppBGDoc={setIsAppBGDoc}
        />
      )}

      {isOpen && <TagSummaryModal data={appDocSummary} isOpen={isOpen} setIsOpen={setIsOpen} />}

      {isDownload && <DownloadProcess isOpen={isDownload} />}

      {isAppBGDoc && (
        <AppBackGroundDocs
          tagData={tagData}
          isOpen={isAppBGDoc}
          appData={appBGDocData}
          setTagData={setTagData}
          setIsOpen={setIsAppBGDoc}
          getAppDataApi={getAppData}
          setIsTagOpen={setIsTagOpen}
          setAppData={setAppBgDocData}
        />
      )}
    </div>
  );
};

export default ApplicationDetails;
