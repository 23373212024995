import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginDetails: {},
};

const loginSlice = createSlice({
  name: "loginData",
  initialState,
  reducers: {
    setLoginDetail(state, action) {
      state.loginDetails = action.payload;
    },
    clearLoginData(state, action) {
      state.loginDetails = {};
    },
  },
});

export const { setLoginDetail, clearLoginData } = loginSlice.actions;
export default loginSlice;
