import React from 'react';
import { TEXT } from '../../services/utils';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Dialog } from '@mui/material';

const DownloadProcess = ({ isOpen }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Dialog open={isOpen}>
        <div className="flex items-center p-4">
          <div className="flex items-center justify-center bg-white" style={{ height: '100%' }}>
            <CircularProgress sx={{ mr: 1 }} size={'2rem'} color="inherit" fourColor />
          </div>
          <p className="text-lg font-medium">{t(TEXT?.DOWNLOAD_IN_PROGRESS)}</p>
        </div>
      </Dialog>
    </div>
  );
};

export default DownloadProcess;
