import {
  Chip,
  List,
  Stack,
  Modal,
  Button,
  ListItem,
  TextField,
  ListItemIcon,
  ListItemText,
  FormHelperText,
  ListItemButton,
  CircularProgress,
} from '@mui/material';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { CheckCircleOutline } from '@mui/icons-material';
import { getRules } from '../../services/dynamicFormHelper';

const unMatchClass = (array, value) => [
  ...new Set(array?.filter((_e) => _e.class !== value?.class).map((e) => e.class)),
];

export const SearchSelectField = ({ field, apiCall, formControl, setShowSnack }) => {
  const [data, setData] = useState(null);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [bulkInput, setBulkInput] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const getData = () => {
    field?.api &&
      apiCall &&
      apiCall(field.api, search)
        .then(setData)
        .catch((e) => setShowSnack({ message: 'Error while getting data', type: 'error' }));
  };

  const getBulkData = (setValue) => {
    setBulkInput('');
    setLoading(true);
    setModalVisible(false);
    const searches = bulkInput.split('\n');
    Promise.all(searches.map((e) => field?.api && apiCall && apiCall(field.api, e)))
      .then((res) => {
        setValue(
          res
            .map((_res, index) => _res?.find((_e, _i) => _e?.desc === searches[index]))
            .filter((e) => e),
        );
      })
      .catch((e) => setShowSnack({ message: 'Error while getting data', type: 'error' }))
      .finally(setLoading);
  };

  return (
    <Controller
      name={field.id}
      rules={getRules(field)}
      control={formControl.control}
      render={({ fieldState: { error }, field: { onChange, onBlur, value } }) => (
        <div className={data || value ? 'rounded-lg border p-3' : ''}>
          <div className="flex gap-2">
            <TextField
              fullWidth
              size="small"
              value={search}
              error={!!error}
              onBlur={onBlur}
              label={field?.label}
              placeholder={field?.placeholder}
              helperText={error?.message || null}
              onChange={(_e) => setSearch(_e.target.value)}
              onKeyPress={(e) => e.charCode === 13 && (e.preventDefault(), getData())}
            />
            <Button variant="contained" onClick={getData} size="small !w-32">
              Search
            </Button>
            {loading ? (
              <div className="flex !w-32 items-center justify-center rounded border border-main">
                <CircularProgress size={24} />
              </div>
            ) : (
              <Button
                size="small"
                variant="contained"
                className="!w-32 !whitespace-nowrap"
                onClick={() => setModalVisible(true)}
              >
                Add Bulk
              </Button>
            )}
          </div>
          {!!value?.length && (
            <>
              <Stack direction="row" className="mt-3 items-center justify-between ">
                <span className="text-sm">
                  {`Class: ${unMatchClass(value, field)?.join(', ')}`}
                </span>
                <span className="whitespace-nowrap text-sm">Selected Records: {value?.length}</span>
              </Stack>
              <Stack direction="row" className="flex-wrap">
                {value?.map((e) => (
                  <Chip
                    className="!mr-2 !mt-2"
                    key={e.id + 'selected'}
                    label={e.title + ' - ' + e.desc}
                    onDelete={() => onChange(value?.filter((_e) => e.id !== _e.id))}
                  />
                ))}
              </Stack>
            </>
          )}

          {!data ? null : !!data.length ? (
            <List className="!mt-3 max-h-64 overflow-y-scroll">
              {data.map((e) => (
                <ListItem key={e?.id}>
                  <ListItemButton
                    className="!m-0 !p-0"
                    onClick={() =>
                      !value?.find((_e) => _e?.id === e?.id) && onChange([...(value || []), e])
                    }
                  >
                    <ListItemText
                      primary={e?.title}
                      secondary={e?.desc}
                      className="!m-0 flex gap-2"
                      primaryTypographyProps={{
                        minWidth: 70,
                        whiteSpace: 'nowrap',
                      }}
                    />
                    {!!value?.find((_e) => _e?.id === e?.id) && (
                      <ListItemIcon>
                        <CheckCircleOutline color="info" className="mx-2 h-5 w-5 min-w-0" />
                      </ListItemIcon>
                    )}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          ) : (
            <FormHelperText>No Data Found</FormHelperText>
          )}
          <Modal
            open={modalVisible}
            onClose={() => setModalVisible(false)}
            className="flex items-center justify-center"
          >
            <div className="absolute flex flex-col rounded-lg border bg-white p-2">
              <p className="mb-2 self-center">Add Bulk Records</p>
              <TextField
                rows={5}
                multiline
                id="bulk_search"
                value={bulkInput}
                onChange={(e) => setBulkInput(e.target.value)}
                onKeyPress={(e) =>
                  e.charCode === 13 && (e.preventDefault(), setBulkInput((e) => (e += '\n')))
                }
                className="w-80"
              />
              <Button
                variant="contained"
                className="!mt-4 w-fit self-center"
                onClick={() => getBulkData(onChange)}
              >
                Search Records
              </Button>
            </div>
          </Modal>
        </div>
      )}
    />
  );
};
