import React, { useEffect, useState } from 'react';
import { WebUrl } from './WebUrl';
import { DateField } from './DatePicker';
import { useForm } from 'react-hook-form';
import { InputField } from './InputField';
import { FieldArray } from './FieldArray';
import { SelectField } from './SelectField';
import { CheckboxField } from './CheckboxField';
import { SpanTextInput } from './SpanTextInput';
import { CheckMarkField } from './CheckMarkField';
import { FileUploadField } from './FileUploadField';
import { RadioButtonField } from './RadioButtonField';
import { SearchSelectField } from './SearchSelectField';
import { Alert, Button, FormControl, Slide, Snackbar } from '@mui/material';

const FieldComponent = {
  webUrl: WebUrl,
  date: DateField,
  text: InputField,
  select: SelectField,
  spanText: SpanTextInput,
  upload: FileUploadField,
  radio: RadioButtonField,
  multiWebURL: FieldArray,
  checkbox: CheckboxField,
  checkMark: CheckMarkField,
  search: SearchSelectField,
};

export const CreateField = ({ fields, apiCall, formControl, setShowSnack }) => {
  return fields?.map((field, i) => {
    const Component = FieldComponent[field?.type];
    const child = field?.child
      ?.filter((_e) => _e?.match === String(formControl.watch(field?.id)))
      .map((e) => e.field);
    return !Component || !field?.id ? null : (
      <div key={field?.id + i} className={child?.length ? 'mt-3 rounded-lg border p-3' : 'mt-3'}>
        <div>
          {field?.prefix && <span className="text-base text-black/60">{field?.prefix}</span>}
          <Component {...{ field, apiCall, formControl, setShowSnack }} />
          {field?.suffix && <span className="text-base text-black/60">{field?.suffix}</span>}
        </div>
        {!!child?.length && (
          <CreateField {...{ fields: child, apiCall, formControl, setShowSnack }} />
        )}
      </div>
    );
  });
};

const DynamicForm = ({ fields, apiCall, onSubmit }) => {
  const formControl = useForm();
  const {
    handleSubmit,
    formState: { errors },
    setFocus,
  } = formControl;

  const [showSnack, setShowSnack] = useState({});

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const firstErrorKey = Object.keys(errors)[0];
      setFocus(firstErrorKey);
    }
  }, [errors, setFocus]);

  const submitAndReset = (data) => {
    onSubmit(data)
      .then(() => formControl.reset())
      .catch((error) => console.error(error));
  };
  // const submitAndReset = (e) => onSubmit(e)?.then(formControl.reset);

  return (
    <div className="mb-5 flex flex-col items-center justify-center">
      <form className="!w-11/12 max-w-xl" onSubmit={handleSubmit(submitAndReset)}>
        {/* <form className="!w-11/12 max-w-xl" onSubmit={formControl.handleSubmit(submitAndReset)}> */}
        <FormControl fullWidth>
          <Snackbar
            autoHideDuration={5000}
            open={!!showSnack.message}
            onClose={() => setShowSnack({})}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            TransitionComponent={(props) => <Slide {...props} direction="left" />}
          >
            <Alert
              sx={{ width: '100%' }}
              severity={showSnack.type}
              onClose={() => setShowSnack({})}
            >
              {showSnack.message}
            </Alert>
          </Snackbar>
          {Array.isArray(fields) && (
            <CreateField
              fields={fields}
              apiCall={apiCall}
              formControl={formControl}
              setShowSnack={setShowSnack}
            />
          )}
          {!!fields?.length && (
            <Button style={{ marginTop: 10, marginBottom: 10 }} variant="contained" type="submit">
              Submit
            </Button>
          )}

          {Object.keys(errors)?.length > 0 && (
            <div className="mt-5 border border-red-400 bg-red-50 p-3">
              <p className="text-base font-bold text-red-700">Please fix the following errors:</p>
              <ul className="pl-7">
                {Object.keys(errors)?.map((key) => (
                  <li className="list-disc marker:text-red-600" key={key}>
                    <p className="text-sm font-medium text-red-700">
                      {errors[key]?.message || `Field ${key} is invalid`}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </FormControl>
      </form>
      <div id="webUrlLoad" />
    </div>
  );
};

export default DynamicForm;
