import icons from '../../config/assets/icons';
import { SelectBox } from 'devextreme-react/select-box';

const DevDropDown = ({
  data,
  width,
  label,
  value,
  height,
  disabled,
  labelMode,
  className,
  placeholder,
  customImage,
  displayValue,
  defaultValue,
  searchEnabled,
  onSelectionChanged,
}) => {
  const renderIcon = () => {
    return <icons.back h={15} w={15} class={' ml-3 flex h-full -rotate-90 items-center'} />;
  };

  return (
    <div>
      <SelectBox
        width={width}
        label={label}
        value={value}
        height={height}
        dataSource={data}
        disabled={disabled}
        className={className}
        labelMode={labelMode}
        searchMode={'contains'}
        displayExpr={displayValue}
        searchEnabled={searchEnabled}
        onSelectionChanged={onSelectionChanged}
        acceptCustomValue={value?.[displayValue]}
        placeholder={placeholder ? placeholder : ''}
        dropDownButtonRender={customImage ? renderIcon : ''}
      />
    </div>
  );
};

export default DevDropDown;
