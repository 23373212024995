import * as React from "react";

const Back = (props) => (
  <svg
    width={props.w}
    height={props.h}
    fill="none"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
    className={props.class}
    onClickCapture={props.onPress}
  >
    <path
      d="M25 32.5 12.5 20 25 7.5"
      stroke="#979797"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Back;
