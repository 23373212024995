import AceEditor from 'react-ace';
import React, { useState } from 'react';
import DynamicForm from '../../components/form';
import { useResolvedPath } from 'react-router-dom';
import { getUrlParams } from '../../services/utils';
import { getFormValues } from '../../services/dynamicFormHelper';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';

const handleParse = (set) => (val) => {
  try {
    const data = JSON.parse(val);
    set(data);
  } catch (error) {
    console.log('🚀 ~ handleParse ~ error:', error);
  }
};

function Preview() {
  const [form, setForm] = useState([]);
  const { pathname } = useResolvedPath();
  const data = pathname.replace(/\/preview\/?/, '');
  const paramData = data && getUrlParams({ data });

  return paramData ? (
    <DynamicForm
      fields={paramData}
      onSubmit={(val) => {
        window?.ReactNativeWebView?.postMessage(JSON.stringify(getFormValues(val)));
      }}
    />
  ) : (
    <div className="my-5 flex min-h-screen w-full flex-col items-center justify-center gap-5 md:flex-row">
      <div>
        <p className="text-3xl">Editor</p>
        <AceEditor
          mode="json"
          tabSize={2}
          theme="github"
          defaultValue={'[\n\n]'}
          onChange={handleParse(setForm)}
          setOptions={{ useWorker: false }}
          editorProps={{ $blockScrolling: true }}
        />
      </div>
      <div className="h-full  w-10/12 max-w-lg ">
        <p className="text-3xl">Preview</p>
        <div className="min-h-[500px] rounded-xl bg-slate-50">
          <DynamicForm
            fields={form}
            onSubmit={(val) => console.log('🚀 ~ RESULT :', getFormValues(val))}
          />
        </div>
      </div>
    </div>
  );
}

export default Preview;
