import { Controller } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import { getRules } from '../../services/dynamicFormHelper';

export const SelectField = ({ field, formControl }) =>
  !field?.options?.length ? null : (
    <Controller
      name={field.id}
      rules={getRules(field)}
      defaultValue={field?.value}
      control={formControl.control}
      render={({ field: props, fieldState: { error } }) => (
        <TextField
          {...props}
          select
          fullWidth
          size="small"
          error={!!error}
          variant="outlined"
          label={field?.label}
          helperText={error?.message || null}
        >
          {field?.options?.map((e) => (
            <MenuItem key={e} value={e}>
              {e}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
