import React, { useState } from 'react';
import { MODE } from '../../services/enum';
import { Controller } from 'react-hook-form';
import { ComponentLoading } from '../loading';
import { post } from '../../services/ApiServices';
import API_CONSTANT from '../../services/ApiConstant';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { getRules } from '../../services/dynamicFormHelper';
import { SESSION_KEY, getFromAsync } from '../../services/utils';
import { decrypt, encrypt } from '../../services/encryptDecryptService';
import { Button, Accordion, TextField, AccordionDetails, AccordionSummary } from '@mui/material';

export const WebUrl = ({ field, formControl, setShowSnack }) => {
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(false);
  const urlToImage = (value, setValue) => {
    if (value?.Url) {
      setLoading(true);
      post(API_CONSTANT.PROCESS_URL_TO_PDF, {
        Mode: MODE.WEB_MODE,
        InputText: encrypt({ URLToProcess: value.Url }),
        AppUserId: getFromAsync(SESSION_KEY.USER_DATA)?.AppUserId,
      })
        .then(({ data }) => setValue({ ...value, ...decrypt(data?.OutPutText) }))
        .catch((e) =>
          setShowSnack({
            type: 'error',
            message: e.data.ErrorMessage || 'Error while getting webpage',
          }),
        )
        .finally(() => setLoading(false));
    }
  };

  return (
    <Controller
      name={field?.id}
      rules={getRules(field)}
      control={formControl.control}
      render={({ fieldState: { error }, field: { value, name, onBlur, onChange } }) => (
        <div>
          {!loading && value?.FileName && (
            <Accordion
              expanded={preview}
              className="mb-3 !border"
              onChange={() => setPreview((e) => !e)}
            >
              <AccordionSummary expandIcon={<ArrowDropDownIcon />} children={value?.FileName} />
              <AccordionDetails>
                <object
                  width="100%"
                  height="500"
                  type="application/pdf"
                  data={'data:application/pdf;base64,' + value?.FileBase64String}
                >
                  <p className="text-red-500">PDF Preview is not supported in your browser</p>
                </object>
              </AccordionDetails>
            </Accordion>
          )}
          <ComponentLoading loading={loading} className={'!h-20'} />
          <div className="flex gap-2">
            <TextField
              id={name}
              name={name}
              size="small"
              error={!!error}
              onBlur={onBlur}
              disabled={loading}
              value={value?.Url}
              className="w-full"
              label={field?.label}
              placeholder={field?.placeholder}
              helperText={error?.message || null}
              onChange={(e) => onChange({ ...(value || {}), Url: e.target.value })}
            />
            <Button
              disabled={loading}
              variant="contained"
              className="!mt-2 h-10"
              onClick={() => urlToImage(value, onChange)}
            >
              Capture
            </Button>
          </div>
        </div>
      )}
    />
  );
};
