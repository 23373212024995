import React, { useEffect, useRef } from 'react';
import WebViewer from '@pdftron/webviewer';

const DocPreview = () => {
  const viewer = useRef(null);

  useEffect(() => {
    WebViewer(
      {
        path: '/lib',
        initialDoc: [
          'https://pdftron.s3.amazonaws.com/downloads/pl/form1.pdf',
          'https://pdftron.s3.amazonaws.com/downloads/pl/report.docx',
        ],
        extension: ['pdf', 'docx'],
        licenseKey: 'demo:1702969357469:7c8f13b00300000000173fc54b90011577ef80bb3f018939222ffe3070',
      },
      viewer.current,
    )
      .then((instance) => {
        const { documentViewer, annotationManager, Annotations } = instance.Core;

        documentViewer.addEventListener('documentLoaded', () => {
          const rectangleAnnot = new Annotations.RectangleAnnotation({
            PageNumber: 1,
            Author: annotationManager.getCurrentUser(),
          });

          annotationManager.addAnnotation(rectangleAnnot);
          annotationManager.redrawAnnotation(rectangleAnnot);
        });
      })
      .catch((e) => {
        console.log('🚀 ~ file: index.js:21 ~ ).then ~ e:', e);
      });
  }, []);

  return (
    <div>
      <div className="mt-4">Doc Viewer Sample</div>
      {<div className="plr3 h-screen" ref={viewer} />}
    </div>
  );
};

export default DocPreview;
