import React from 'react';
import { TEXT } from '../../services/utils';
import icons from '../../config/assets/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setDocURL } from '../../store/application/reducer';

const DocSideBar = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { app } = useSelector((state) => state);

  return (
    <>
      <div className={`${open ? 'w-72' : 'w-[0px]'} fixed left-0 top-24 z-40 h-screen`}>
        <div className="h-[calc(100%-96px)] overflow-y-auto border-r bg-gray-50">
          {app?.docURLSList?.ListOfURLs?.map((list) => {
            const substringText = list?.FileURL.substring(list?.FileURL.lastIndexOf('/') + 1);
            const ext = substringText.split('.').pop();
            const truncateText =
              substringText?.length > 20
                ? substringText.substring(0, 20) + '...' + ext
                : substringText;

            return (
              <div
                onClick={() => {
                  dispatch(setDocURL(list));
                }}
                className="flex cursor-pointer items-center space-y-2 border-b border-b-dark-grey-border p-2 text-center font-medium text-gray-900 hover:rounded-lg hover:bg-white"
              >
                <p className="overflow-hidden text-ellipsis whitespace-normal break-words p-2 text-base font-medium text-black">
                  {truncateText}
                </p>
              </div>
            );
          })}
        </div>

        <div
          onClick={() => setOpen(!open)}
          className="absolute right-[-95px] top-[45%] mt-2 inline-flex w-max rotate-90 cursor-pointer items-center rounded-t-lg border border-b-0 border-gray-200 p-2 text-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          {open ? (
            <div className="-rotate-90">
              <icons.arrow w={17} h={18} />
            </div>
          ) : (
            <div className="rotate-90">
              <icons.arrow w={17} h={18} />
            </div>
          )}

          <span class="pl-3 text-base">{t(TEXT?.DOCUMENTS_LIST)}</span>
        </div>
      </div>
    </>
  );
};

export default DocSideBar;
