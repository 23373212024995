import * as React from 'react';
import Images from '../../config/Images';
import IconImage from '../image/IconImage';
import TextInput from '../inputs/TextInput';
import { TEXT } from '../../services/utils';
import { Grid, Typography } from '@mui/material';
import AppStyles from '../../screens/AppStyles';
import { useTranslation } from 'react-i18next';
import { HEADER_COLOR, LIGHT_BLUE, WHITE } from '../../config/color';

export default function Header({ isBack, srcHeading, matches, ...props }) {
  const { t } = useTranslation();

  return (
    <Grid
      container
      style={{
        paddingTop: '1.25%',
        alignItems: 'center',
        paddingBottom: '1.25%',
        background: isBack ? HEADER_COLOR : WHITE,
      }}
    >
      <Grid
        item
        md={srcHeading ? 1 : 2}
        sm={srcHeading ? 1 : 2}
        xs={srcHeading ? 2 : 2}
        display={'flex'}
        style={{ paddingLeft: '1.7%' }}
      >
        {isBack ? (
          <Grid onClick={props?.backPress}>
            <IconImage name={props?.img} width={props?.width} height={props?.height} isEye />
          </Grid>
        ) : (
          <Grid>
            <IconImage
              name={props?.img}
              width={matches ? 100 : props?.width}
              height={matches ? 100 : props?.height}
              isEye
            />
          </Grid>
        )}
      </Grid>
      {srcHeading && (
        <Grid item md={1.5} sm={1.5} xs={isBack ? 6 : 6} display={'flex'}>
          <Typography sx={[matches ? AppStyles?.fs16BB : AppStyles?.fs25BB]}>
            {srcHeading}
          </Typography>
        </Grid>
      )}
      <Grid
        item
        xs={srcHeading ? 9 : 8}
        md={srcHeading ? 7.5 : 8.5}
        sm={srcHeading ? 7.1 : 8.1}
        sx={{
          pl: 2,
          pr: 2,
          mt: matches ? 2 : 0,
          mb: matches ? 2 : 0,
        }}
      >
        <Grid
          item
          md={5}
          sm={11}
          xs={11}
          sx={[
            AppStyles.inputSubView,
            {
              pl: 2,
              pr: 2,
              mb: 0,
              borderRadius: 2,
              display: 'flex',
              background: LIGHT_BLUE,
            },
          ]}
        >
          <IconImage name={Images.Search} isEye={true} />
          <Grid item md={12} sm={12} xs={12}>
            <TextInput
              id="email"
              border="true"
              fullWidth={true}
              variant="standard"
              value={props?.searchValue}
              onChange={props?.onChange}
              placeholder={t(TEXT?.SEARCH)}
              sx={{ p: matches ? 1 : 1.688 }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        md={1.5}
        sm={1.5}
        display={'flex'}
        justifyContent={'end'}
        xs={srcHeading ? 3 : 1.5}
        onClick={props?.onLogout}
        sx={{ pr: matches ? 1 : 2, cursor: 'pointer' }}
      >
        <Typography sx={matches ? AppStyles?.fs12B : AppStyles?.fs20B}>
          {t(TEXT?.LOGOUT)}
        </Typography>
      </Grid>
    </Grid>
  );
}
